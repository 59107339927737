import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Sheets 아이콘
 * @param props
 * @returns
 */
export function Sheets(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#4DA855"
      />
      <path
        d="M13.9101 3.43043C13.9101 3.19019 13.7101 3 13.4501 3H2.55009C2.30009 3 2.09009 3.19019 2.09009 3.43043V12.5696C2.09009 12.8098 2.29009 13 2.55009 13H13.4601C13.7101 13 13.9201 12.8098 13.9201 12.5696V3.43043H13.9101ZM3.00009 6.91391H5.27009V9.08609H3.00009V6.91391ZM6.18009 6.91391H13.0001V9.08609H6.18009V6.91391ZM13.0001 3.87087V6.04304H6.18009V3.87087H13.0001ZM5.27009 3.87087V6.04304H3.00009V3.87087H5.27009ZM3.00009 12.1391V9.96697H5.27009V12.1391H3.00009ZM6.18009 12.1391V9.96697H13.0001V12.1391H6.18009Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default Sheets;
