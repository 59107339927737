import dynamic from 'next/dynamic';

export const CompanyFolderManagerPage = dynamic(
  () => import('./company-folder/manager'),
);
export const CompanyFolderManagementPage = dynamic(
  () => import('./company-folder/management'),
);

export const ReportFormPage = dynamic(() => import('./report-form'));
export const SurveyStatusPage = dynamic(() => import('./survey/survey-status'));
export const SurveyManagerSettingPage = dynamic(
  () => import('./survey/survey-manager'),
);
export const CommunitySetupManagementPage = dynamic(
  () => import('./community/community-setup-management'),
);
export const CommunityAllStatusPage = dynamic(
  () => import('./community/community-all-status'),
);
export const CommunityBoardStatusPage = dynamic(
  () => import('./community/community-board-status'),
);
export const CommunityBoardStatusByCommunityPage = dynamic(
  () => import('./community/community-board-status-by-community'),
);
export const WorksManagerPage = dynamic(() => import('./works/manager'));
export const WorksAppListPage = dynamic(() => import('./works/app-list'));
export const PcMessengerSetting = dynamic(() => import('./messenger/pc'));
export const MobileMessengerSetting = dynamic(
  () => import('./messenger/mobile'),
);
export const AllBoardStatusPage = dynamic(
  () => import('./board/all-board-status'),
);

export const ContactSettingPage = dynamic(
  () => import('./contact/contact-setting'),
);

export const PublicContactPage = dynamic(
  () => import('./contact/public-contact'),
);
export const EntireContactPage = dynamic(
  () => import('./contact/entire-contact'),
);
export const DepartmentBoardStatusPage = dynamic(
  () => import('./board/department-board-status'),
);
export const WebFolderManagerPage = dynamic(() => import('./webfolder'));
export const DashboardManagerPage = dynamic(
  () => import('./home/dashboard-manager'),
);
export const MpDashboardManagerPage = dynamic(
  () => import('./mp-home/dashboard-manager'),
);
export const GlobalBoardStatusPage = dynamic(
  () => import('./board/global-board/index'),
);
export const GlobalCalendarPage = dynamic(
  () => import('./calendar/global-calendar/index'),
);
export const MailGroupPage = dynamic(() => import('./mail/mail-group/index'));
export const AssetCategoryPage = dynamic(
  () => import('./asset/asset-category/index'),
);
export const MailAliasPage = dynamic(() => import('./mail/alias/index'));
export const MailBulkPage = dynamic(() => import('./mail/bulk/index'));
export const DomainMailBoxPage = dynamic(
  () => import('./mail/domain-mail-box/index'),
);
export const MailConfigPage = dynamic(() => import('./mail/config/index'));
export const MemberPersonalMailConfigPage = dynamic(
  () => import('./mail/member-personal-config/index'),
);

export const MailStatPage = dynamic(() => import('./mail/statistics'));
export const MailBannerPage = dynamic(() => import('./mail/banner'));

export const LinkPlusManagerPage = dynamic(() => import('./link-plus'));
