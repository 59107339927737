import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as docsPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/docs';
export const menuKeyCode = 'dop-employee-docs';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: <I18nText namespace={i18nNamespace} i18nKey="문서관리" />,
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="문서함 관리" />,
        menuPath: docsPaths.HOME_PATH,
        sortOrder: 0,
        iframeSrc: '/gw/iframe/admin/docs',
      },
    ],
  },
];
