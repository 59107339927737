import { useSuspenseQuery } from '@tanstack/react-query';
import { useToastMessage } from '../../../shared/ui/toast';
import {
  downloadFile,
  fetchNotificationDetail,
  NOTI_DETAIL_QUERY_KEY,
} from '../apis/notification';
import { FileInfo } from '../types';
import { useContext } from '../ui/context';
import { DUMMY_NOTI_DATA } from './dummy-info';

export const useNotificationDetail = () => {
  const toast = useToastMessage();
  const { target, currentDetailId, setCurrentDetailId } = useContext();

  const { data, isLoading, error } = useSuspenseQuery({
    queryKey: [NOTI_DETAIL_QUERY_KEY, currentDetailId],
    queryFn: () => {
      if (!currentDetailId) return Promise.resolve(DUMMY_NOTI_DATA);
      return fetchNotificationDetail(currentDetailId, target);
    },
  });

  const onClickDownloadFile = (notificationId: number, fileInfo: FileInfo) => {
    void downloadFile(notificationId, fileInfo).catch((e: Error) =>
      toast.warning(e.message),
    );
  };

  return {
    notificationItem: data,
    isLoading,
    error,
    setCurrentDetailId,
    onClickDownloadFile,
  };
};
