import { APPS_MANAGEMENT } from '../../main/menuPaths';

const menuKeyPath = APPS_MANAGEMENT + '/approval';

export const FORM_PATH = menuKeyPath + '/form';
export const CONFIG_PATH = menuKeyPath + '/config';
export const MANAGER_PATH = menuKeyPath + '/manager';
export const APPR_LINE_RULE_PATH = menuKeyPath + '/apprlinerule';
export const DEPT_FOLDER_PATH = menuKeyPath + '/deptfolder';
export const MANAGE_OFFICIAL_PATH = menuKeyPath + '/manage/official';
export const CONFIG_DOCNUMBER_PATH = menuKeyPath + '/config/docnum';
export const SECURITY_LEVEL_PATH = menuKeyPath + '/securitylevel';
export const CONFIFG_SIGN_REGIST_PATH = menuKeyPath + '/config/sign/regist';
export const DEPT_PATH = '/dept';
export const MANAGER_DOCUMENT_PATH = menuKeyPath + '/manage/document';
export const STATISTICS_PATH = menuKeyPath + '/statistics';
export const DEPT_STATISTICS_PATH = menuKeyPath + '/deptstatistics';
