import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { AuthorizedAppCode, MenuInfo } from '../../../types';
import { openSubMenusAtom } from '../../../store/openSubMenusAtom';
import { recentUsedMenuInfoAtom } from '../../../store/recentUsedAppsAtom';
import { selectedMainCategoryAtom } from '../../../store/selectedMainCategoryAtom';
import { currentSelectedMenuInfoAtom } from '../../../store/currentSelectedMenuInfoAtom';
import {
  APP_MANAGEMENT_MENU_PATH,
  getMenuInfoWithPath,
  getSubMenuInfos,
  RECENT_MENU_PATH,
  FAVORITE_MENU_PATH,
} from '../../../menus';
import { SubMenuList } from './SubMenuList';
import { favoriteMenuAtom } from '../../../store/favoriteMenuAtom';

interface Props {
  employeeMenus: AuthorizedAppCode[];
  businessMenus: AuthorizedAppCode[];
}

export function Sub({ employeeMenus, businessMenus }: Props) {
  const { t } = useTranslation();
  const favoriteMenuInfos = useAtomValue(favoriteMenuAtom);
  const [firstLoaded, setFirstLoaded] = useState(true);
  const [menuInfos, setMenuInfos] = useState<MenuInfo[]>([]);
  const [currentPortalType, setCurrentPortalType] = useState<
    'EMPLOYEE' | 'BUSINESS'
  >('EMPLOYEE');
  const selectedMainPath = useAtomValue(selectedMainCategoryAtom);
  const recentMenuInfos = useAtomValue(recentUsedMenuInfoAtom);
  const [openSubMenus, setOpenSubMenus] = useAtom(openSubMenusAtom);
  const setCurrentSelectedMenu = useSetAtom(currentSelectedMenuInfoAtom);

  useEffect(() => {
    if (selectedMainPath === FAVORITE_MENU_PATH) {
      const favorite = favoriteMenuInfos
        ? favoriteMenuInfos
            .map((info) => getMenuInfoWithPath(info.menuPath))
            .filter((menu) => menu !== undefined)
        : [];
      setMenuInfos(favorite);
    } else if (selectedMainPath === RECENT_MENU_PATH) {
      const recent = recentMenuInfos
        .map((menu) => getMenuInfoWithPath(menu.menuPath))
        .filter((menu) => menu !== undefined);
      setMenuInfos(recent);
    } else {
      setMenuInfos(
        getSubMenuInfos(
          selectedMainPath,
          currentPortalType === 'EMPLOYEE'
            ? employeeMenus.map((menu) => menu.appCode)
            : businessMenus.map((menu) => menu.appCode),
        ),
      );
    }
  }, [
    selectedMainPath,
    currentPortalType,
    employeeMenus,
    businessMenus,
    recentMenuInfos,
    favoriteMenuInfos,
  ]);

  useEffect(() => setFirstLoaded(true), []);

  useEffect(() => {
    if (firstLoaded && menuInfos.length > 0) {
      setFirstLoaded(false);
      const subMenu = menuInfos.at(0);
      if (subMenu) {
        const newOpenMenus = new Set(openSubMenus);
        newOpenMenus.add(subMenu.menuPath);
        setOpenSubMenus(newOpenMenus);
      }
      const leafMenu = menuInfos.at(0)?.children?.at(0);
      if (leafMenu)
        setCurrentSelectedMenu({
          menuPath: leafMenu.menuPath,
          menuCode: leafMenu.menuCode,
        });
    }
  }, [
    firstLoaded,
    menuInfos,
    setOpenSubMenus,
    setCurrentSelectedMenu,
    openSubMenus,
  ]);

  return (
    <div className="flex-shrink-0 w-[260px] h-full border-r-[1px] pt-[40px] pb-[10px] flex flex-col">
      {selectedMainPath === APP_MANAGEMENT_MENU_PATH && (
        <div className="relative flex gap-[4px] mx-[24px] my-[8px] p-[4px] bg-[#EEF1F7] h-[46px] rounded-[12px] z-0">
          <div
            className={clsx(
              'absolute top-[4px] bottom-[4px] w-[48%] rounded-[8px] bg-[#F9FBFC] shadow shadow-[0px_1px_8px_0px_rgba(0, 0, 0, 0.10)] -z-0',
              {
                'translate-x-[0] transition': currentPortalType === 'EMPLOYEE',
              },
              {
                'translate-x-[101%] transition':
                  currentPortalType === 'BUSINESS',
              },
            )}
          />
          <Button
            className="h-full flex-1 rounded-[8px] text-[#363636] z-10 bg-transparent text-center"
            size="none"
            onClick={() => setCurrentPortalType('EMPLOYEE')}
          >
            {t('service.employeePortal.name')}
          </Button>
          <Button
            className="h-full flex-1 rounded-[8px] text-[#363636] z-10 bg-transparent text-center"
            size="none"
            onClick={() => setCurrentPortalType('BUSINESS')}
          >
            {t('service.businessPortal.name')}
          </Button>
        </div>
      )}
      <SubMenuList.Root>
        {menuInfos.map((menuInfo) => (
          <SubMenuList.Item key={menuInfo.menuPath} menuInfo={menuInfo} />
        ))}
      </SubMenuList.Root>
    </div>
  );
}
