import { Contents } from './contents';
import { Header } from './Header';
import { Menu } from './menu';
import { Root } from './Root';

export interface Props {}

// TODO: ServiceMap 컴포넌트는 추후 리팩토링. -> 현재 퍼블리싱한 컨텐츠만 보여주고 있음.
export function ServiceMap() {
  return (
    <Root>
      <Header />
      <Contents>
        <Menu />
      </Contents>
    </Root>
  );
}

export default ServiceMap;
