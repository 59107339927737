'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { useToastMessage } from '../../../shared/ui/toast';
import { usePushNotification } from '../hooks/use-push-notification';
import { PushDevice } from '../types';
import { ForegroundNotification } from './foreground-notification';

interface Props {
  children: React.ReactNode;
  device: PushDevice;
}

interface FCMContextType {
  fcmToken?: string;
  isNotificationEnabled: boolean;
  setIsNotificationEnabled: (enabled: boolean) => void;
}

const FCMContext = createContext<FCMContextType>({
  fcmToken: '',
  isNotificationEnabled: true,
  setIsNotificationEnabled: () => {},
});

export const useFCM = () => {
  return useContext(FCMContext);
};

export function FCMProvider({ children, device }: Props) {
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(true);

  const {
    fcmToken,
    initNotification,
    deInitNotification,
    foregroundNotificationInfo,
  } = usePushNotification(device, 'WEBPUSH');
  const { info } = useToastMessage();

  useEffect(() => {
    void initNotification();

    return () => {
      deInitNotification();
    };
  }, [initNotification, deInitNotification]);

  useEffect(() => {
    if (foregroundNotificationInfo) {
      info(
        <ForegroundNotification
          notificationInfo={foregroundNotificationInfo}
        />,
      );
    }
  }, [foregroundNotificationInfo, info]);

  return (
    <FCMContext
      value={{
        fcmToken,
        isNotificationEnabled,
        setIsNotificationEnabled: setIsNotificationEnabled,
      }}
    >
      {children}
    </FCMContext>
  );
}
