import { AppList } from './app-list';

interface Props {
  scrollable: boolean;
}
function LinkPlusList({ scrollable }: Props) {
  return (
    <AppList
      type="LINK_PLUS"
      scrollable={scrollable}
      offset={scrollable ? 20 : 5}
    />
  );
}

export default LinkPlusList;
