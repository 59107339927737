import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '../../../../../../shared/ui/button';
import { useTranslation } from '../../../../../../shared/lib/i18n/client/use-translation';
import { RemoveIcon } from '@dop-ui/icons/react/dop/16';

interface Props {
  onReset: () => void;
}

export function ManageCancelDialog({ onReset }: Props) {
  const { t } = useTranslation('component');

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button size="md" shape="round" variant="outline" colorset="level2">
          <RemoveIcon size={15} />
          {t('dashboard.cancel')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay !z-10" />
        <Dialog.Content className="DialogContent !z-20">
          <div className="flex flex-row justify-between items-center">
            <Dialog.Title className={'text-[#1A1A1A] text-[20px] font-[500]'}>
              {t('dashboard.edit.cancel')}
            </Dialog.Title>
          </div>

          <Dialog.Description className="my-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
            {t('manage.dashboard.edit.cancel.description')}
          </Dialog.Description>
          <div className="flex flex-row justify-end gap-2">
            <Dialog.Close asChild>
              <Button
                size="md"
                shape="rect"
                onClick={onReset}
                variant="solid"
                colorset="critical"
              >
                {t('dashboard.edit.cancel')}
              </Button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <Button size="md" shape="rect" variant="outline">
                {t('dashboard.close')}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
