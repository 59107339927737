'use client';

import { useWindowResizeObserver } from '@daouoffice/ui/lib/hooks/useWindowResizeObserver';
import { useScreenLock } from '@dop-ui/react/widgets/screen-lock';
import * as React from 'react';
import type { IUserMenuInfo } from '../../types';
import { AppMoreMenuLayer } from './AppMoreMenuLayer';
import { ItemButton } from './ItemButton';

// 앱 버튼 하나당 높이 (버튼 높이 + 마진)
export const APP_DOCK_ITEM_HEIGHT_PX = 57 + 12;

export interface Props {
  data: IUserMenuInfo[];
  pathname: string;
}
/**
 * AppsDockClient 컴포넌트
 *
 * [참고: 작동 원리 설명]
 * 1. 모든 버튼을 그리도록 해서, `.navigation`의 높이를 우선 계산해야함.
 *    - 이는 `.navigation`이 고정값 높이(height)가 아닌 유동값에 최대 높이 설정(max-height)이 되어 있기 때문.
 *    - 모든 버튼을 그리더라도 `.navigation > .group_menu_list`에 `overflow-y: hidden`이 설정되어 있기 때문에,
 *      max-height를 넘지 않음
 * 2. 컴포넌트가 마운트된 후, `.navigation`요소의 현재 높이를 계산해서 실제 보여지는 영역에서 그려질 수 있는
 *    버튼의 수를 계산함. `APP_DOCK_ITEM_HEIGHT_PX` 상수로 우선 정의하였고, 스타일이 변경되면 이 값도 수정해야함.
 * 3. 계산된 버튼 수를 이용하여 실제로 표현할 수 있는 버튼을 전체 메뉴 목록에서 잘라내서 다시 렌더링.
 * 4. 만약, 숨겨진 메뉴가 존재한다면 더보기 버튼과 더보기 레이어를 추가.
 * 5. 만약, 현재 메뉴가 잘라낸 버튼 내에 없다면 현재 메뉴를 `.group_menu_list` 요소와 더보기 버튼 사이에 별도로 표시
 *    (`.active_menu`)
 *
 * [참고: 더보기와 .active_menu의 위치]
 * - 더보기와 `.active_menu`는 `.navigation` 하위 요소이나, 출력 여부는 선택이어서 `.navigation`에 실제 표현할 수 있는
 *   버튼의 갯수를 계산할 때 제외해야 함. 이는 메뉴가 gnb에 모두 표현할 수 있을 정도로 적은 갯수일 때는 gnb의 모든 높이를
 *   차지하지 않기 때문에, 이를 포함해서 계산을 할 수가 없음
 * - 하지만, 더보기와 `.active_menu`는 아래쪽 footer 영역의 환경설정과 조직도 버튼 영역을 침범할 있기 때문에,
 *   두 버튼의 자리확보는 해야함.
 * - 위 두가지 조건의 처리를 위해, `.navigation`의 최대 높이(`max-height`) 지정 시, 아래쪽 `.footer` 높이 뿐만 아니라,
 *   두 버튼이 겹치지 않고 출력되기 위해 추가 높이를 고려하여 마진을 계산.
 *   `.navigation` 요소는 `overflow:auto` 이기 때문에 더보기와 .active_menu가 `.navigation`에 추가되더라도
 *   넘쳐서 (overflow: show) 표현됨.
 * - 위의 작동 방식으로 인해 더보기와 `.active_menu`가 선택적으로 보이더라도 메뉴를 올바르게 표시할 수 있게됨.
 *
 * @returns
 */
export function AppsDockClient({ data: userMenus, pathname }: Props) {
  const [visiableMaxCount, setVisiableMaxCount] = React.useState<number>(0);
  const navRef = React.useRef<HTMLDivElement>(null);
  // 보이는 영역을 잘라낸 후 GNB 영역이 줄어든 후 다시 계산되는 것을 막기 위한 레퍼런스
  const isComputedHeight = React.useRef<boolean>(false);
  const adjustNavAear = React.useCallback(() => {
    // console.log('called adjustNavAear()');
    if (!navRef.current) return;
    if (isComputedHeight.current) return;

    const areaHeight = navRef.current.clientHeight;
    const maxCnt = Math.floor(areaHeight / APP_DOCK_ITEM_HEIGHT_PX);
    // console.log('영역 높이(clientHeight 이용): ', navRef.current.clientHeight);
    // console.log('현재 뷰 영역 최대 노출 메뉴 갯수: ', maxCnt);
    setVisiableMaxCount(maxCnt);
    isComputedHeight.current = true; // 재계산을 막는다.
  }, [isComputedHeight]);

  const resizeHandler = () => {
    // 다시 계산하도록 플래그를 설정한다.
    isComputedHeight.current = false;
    adjustNavAear();
  };

  // url로 접근했을 때, ScreenLock 로직
  const { locked } = useScreenLock();
  React.useEffect(() => {
    const app = userMenus.find((item) => {
      return item.url === '/'
        ? item.url === pathname
        : pathname.includes(item.url);
    });
    app &&
      locked({
        appCode: app.appCode,
        appName: app.name,
        appUrl: app.url,
        useLock: app.useLock,
      });
  }, [locked, pathname, userMenus]);

  React.useEffect(() => {
    adjustNavAear();
  }, [adjustNavAear]);

  // 윈도우 resize 대응
  useWindowResizeObserver(resizeHandler, { debounced: true });

  const acivatedMenuIndex = userMenus.findIndex((value) =>
    pathname.includes(value.url),
  );
  const totalMenuCount = userMenus.length;
  // console.log('visiableMaxCount: ', visiableMaxCount);
  // console.log('totalMenuCount: ', totalMenuCount);
  const isOverflowed =
    visiableMaxCount > 0 ? totalMenuCount > visiableMaxCount : false;
  const visibleMenuLastIndex = isOverflowed
    ? visiableMaxCount - 1
    : totalMenuCount - 1;
  // console.log('visibleMenuLastIndex: ', visibleMenuLastIndex);
  const isInvisibleCurMenu = acivatedMenuIndex > visibleMenuLastIndex;

  const visibleMenus = isOverflowed
    ? [...userMenus].slice(0, visiableMaxCount)
    : [...userMenus]; // 주의: 반드시 복사해야 함.
  // console.log('visibleMenus: ', visibleMenus);
  const moreMenus = isOverflowed ? [...userMenus].slice(visiableMaxCount) : [];
  // console.log('처리 후 userMenus: ', userMenus);

  const checkActivatedUrl = (url: string) => {
    return url === '/' || url === '/home'
      ? url === pathname
      : pathname.includes(url);
  };

  return (
    <nav className="appsdock navigation" ref={navRef}>
      <ul className="group_menu_list">
        {visibleMenus.map((item) => (
          <ItemButton
            as="li"
            data={item}
            key={item.uid}
            // 임시: 우선 앱 URL을 이용하여 활성화를 결정.
            activated={checkActivatedUrl(item.url)}
          />
        ))}
      </ul>
      {isInvisibleCurMenu && (
        <div className="active_menu">
          <ItemButton
            data={userMenus[acivatedMenuIndex]}
            key={userMenus[acivatedMenuIndex].uid}
            activated={checkActivatedUrl(userMenus[acivatedMenuIndex].url)}
          />
        </div>
      )}
      {moreMenus.length > 0 && (
        <AppMoreMenuLayer
          data={moreMenus.filter((item) => item.url !== pathname)}
        />
      )}
    </nav>
  );
}

export default AppsDockClient;
