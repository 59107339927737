import { clsx } from 'clsx';
import { useTranslation } from '../../../../shared/lib/i18n/client/use-translation';
import { useCategoryList } from '../../model/use-category-list';
import { useContext } from '../context';
import styles from './styles.module.css';

export function SideMenu() {
  const { t } = useTranslation('component');

  const { categoryList } = useCategoryList();

  const { currentCategory, setCurrentCategory } = useContext();

  return (
    <div className={styles.SideMenuContainer}>
      <p className={styles.Title}>{t('customerNoti.title')}</p>
      <div className={styles.MenuList}>
        <button
          onClick={() => setCurrentCategory('ALL')}
          className={clsx(styles.MenuItem, {
            [`${styles.selected}`]: currentCategory === 'ALL',
          })}
        >
          {t('customerNoti.category.ALL')}
        </button>
        {categoryList &&
          categoryList.length > 0 &&
          categoryList.map((category) => (
            <button
              key={category}
              onClick={() => setCurrentCategory(category)}
              className={clsx(styles.MenuItem, {
                [`${styles.selected}`]: currentCategory === category,
              })}
            >
              {t(`customerNoti.category.${category}`)}
            </button>
          ))}
      </div>
    </div>
  );
}
