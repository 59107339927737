import { IconArrowLeft } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { serviceMapOpenStateAtom } from '../../../../store/serviceMapOpenStateAtom';

export interface Props {}

export function Title() {
  // TODO : serviceMapOpenState jotai 코드 추가 시, serviceMap 열기, 닫기 기능 추가.
  const { t } = useTranslation('component');
  const [, setServiceMapOpenState] = useAtom(serviceMapOpenStateAtom);

  return (
    <div className="flex items-center pt-[50px] px-[52px]">
      <button
        className="p-[8px] rounded-[32px] hover:bg-[#f2f4f7] flex items-center justify-center"
        onClick={() => setServiceMapOpenState((prev) => !prev)}
      >
        <IconArrowLeft color="#1C1C1C" size={24} />
      </button>
      <h1 className="text-3xl font-[500]">
        {t('globalconfig.servicemap.title')}
      </h1>
    </div>
  );
}

export default Title;
