import * as React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { readGadgetSpecs } from '../../../../apis';
import { GadgetSpecProps } from '../../../../types';
import { useDashboardContext } from '../../../../context';
import ManageGadgetSpecItem from './manage-gadget-spec-item';
import { useTranslation } from '../../../../../../shared/lib/i18n/client/use-translation';

function ManageGadgetSpecListInner() {
  const { t } = useTranslation('component');
  const {
    state: { currentDashboard },
    actions: { getGadgetComponent },
  } = useDashboardContext();
  const { data: gadgetSpecs, error } = useSuspenseQuery({
    queryKey: ['gadget-specs'],
    queryFn: () => readGadgetSpecs('BUSINESS'),
  });

  if (error || !currentDashboard) {
    console.error(error);
    return;
  }

  const installedGadgets = gadgetSpecs.gadgetTemplateList.filter((spec) =>
    getGadgetComponent(spec.name),
  );

  return (
    <ul className="flex gap-x-[24px] !overflow-x-auto py-[20px] mb-6">
      {installedGadgets.length === 0 && (
        <li className="flex items-center justify-center w-[240px] h-[160px] bg-gray-100 rounded-lg">
          {t('dashboard.gadget.empty')}
        </li>
      )}
      {installedGadgets.map((gadgetSpec: GadgetSpecProps) => (
        <ManageGadgetSpecItem
          key={`gadgetSpec-${gadgetSpec.gadgetTemplateId}`}
          spec={gadgetSpec}
          dashboardId={currentDashboard.dashboardId}
        />
      ))}
    </ul>
  );
}

export default function ManageGadgetSpecList() {
  return (
    <React.Suspense fallback={<div>Loding...</div>}>
      <ManageGadgetSpecListInner />
    </React.Suspense>
  );
}
