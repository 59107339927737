export const mockedLinkPlusList = {
  code: '200',
  message: 'OK',
  data: [
    {
      id: 14,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 22,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 19,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 17,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 3,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 8,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 142,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰2',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 222,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어2',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 192,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워2',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 172,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU2',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 32,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰2',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 82,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝2',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 143,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰3',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 223,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어3',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 193,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워3',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 173,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU3',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 33,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰3',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 83,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝3',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 144,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰4',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 224,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어4',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 194,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워4',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 174,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU4',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 34,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰4',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 84,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝4',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
  ],
};
