import * as React from 'react';
import { NotificationTarget, NotificationCategory } from '../types';

interface IContextValue {
  target: NotificationTarget;
  currentCategory: NotificationCategory;
  currentDetailId?: number;
}

interface IContextAction {
  setCurrentCategory: React.Dispatch<
    React.SetStateAction<NotificationCategory>
  >;
  setCurrentDetailId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const ContextValue = React.createContext<IContextValue>({
  target: 'EMPLOYEE_PORTAL',
  currentCategory: 'ALL',
});

export const ContextAction = React.createContext<IContextAction>({
  setCurrentCategory: () => {},
  setCurrentDetailId: () => {},
});

interface Props {
  target: NotificationTarget;
}

export function Provider({ target, children }: React.PropsWithChildren<Props>) {
  const [currentCategory, setCurrentCategory] =
    React.useState<NotificationCategory>('ALL');
  const [currentDetailId, setCurrentDetailId] = React.useState<
    number | undefined
  >();

  const contextValue = {
    target,
    currentCategory,
    currentDetailId,
  };

  const contextAction = {
    setCurrentCategory,
    setCurrentDetailId,
  };

  return (
    <ContextAction value={contextAction}>
      <ContextValue value={contextValue}>{children}</ContextValue>
    </ContextAction>
  );
}

export const useContextValue = () => React.useContext(ContextValue);
export const useContextAction = () => React.useContext(ContextAction);
export const useContext = () => ({
  ...useContextValue(),
  ...useContextAction(),
});
