import clsx from 'clsx';
import { ReactNode } from 'react';
import { ExclamationCircleIcon } from '../../../icons/dop/24/ExclamationCircle';
import { useContext } from '../Context';
import { AppListDirection, MapAppInfo } from '../types';

/**
 * ServiceMap.AppList 컴포넌트 속성
 */
export interface Props {
  /** @property 내부 앱 전체 정보 */
  data: MapAppInfo[];
  /** @property 리스트 제목 */
  title: string;
  /** @property 화면에서 리스트의 위치 */
  direction: AppListDirection;
  children?: ReactNode | ((appInfo: MapAppInfo) => ReactNode);
}

/**
 * ServiceMap.AppList 컴포넌트
 * @returns
 */
export function AppList({ data, title, direction, children }: Props) {
  const { isUsedOnly, t } = useContext();
  const renderChildren =
    typeof children === 'function' ? children : () => children;

  const inUseApps = data.filter((info) => info.status !== 'beforeuse');
  const notInUseApps = data.filter((info) => info.status === 'beforeuse');

  return (
    <div className={clsx('column', direction)}>
      <h2 className="title heading_large semibold">{title}</h2>
      <div className="group_list use">
        <h3 className="blind">{t('serviceMap.app.label.inUseApps')}</h3>
        <ul className="group_app_list">{inUseApps.map(renderChildren)}</ul>
      </div>
      {/** TODO: 아직 사용 전인 앱이 존재하지 않는 경우 아예 보여주지 않는 로직 추가 == 기획 확인 후 만약 보여줘야하는 경우가 생긴다면 수정해야함. */}
      {isUsedOnly ? (
        <></>
      ) : notInUseApps.length === 0 ? (
        <></>
      ) : (
        <div className="group_list not_in_use">
          <div className="wrap_title">
            <h3 className="title heading_small semibold">
              {t('serviceMap.app.label.notInUseApps')}
            </h3>
            <div role="button" className="btn_icon small">
              <ExclamationCircleIcon />
            </div>
          </div>
          <ul className="group_app_list">{notInUseApps.map(renderChildren)}</ul>
        </div>
      )}
    </div>
  );
}

export default AppList;
