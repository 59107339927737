'use client';

import { useEffect } from 'react';

import { Style, loadScript, preLoadDOResources } from './ClientUtil';
import '../OrgChart/organization.css';

interface Props {
  app?: string;
  page?: string;
  isAdmin?: boolean;
  isMobile?: boolean;
}

const NEXT_PUBLIC_DO_PREFIX = process.env['NEXT_PUBLIC_DO_PREFIX'];
const DO_RESOURCE_PATH = `${NEXT_PUBLIC_DO_PREFIX}resources`;

const USER_STYLES = [
  { href: `${DO_RESOURCE_PATH}/css/fonts/notosans.css` },
  { href: `${DO_RESOURCE_PATH}/css/master_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_app_style2.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_app_style3.css` },
  { href: `${DO_RESOURCE_PATH}/css/doc_editor.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_home_dashboard.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_print.css`, media: 'print' },
  { href: `${DO_RESOURCE_PATH}/css/go_color_default.css` },
  { href: `${DO_RESOURCE_PATH}/css/doc_editor.css`, media: 'screen' },
  { href: `${DO_RESOURCE_PATH}/css/go_prototype.css`, media: 'screen' },
  {
    href: `${DO_RESOURCE_PATH}/js/vendors/jquery/plugins/jstree/themes/default/style.css`,
  },
  {
    href: `${DO_RESOURCE_PATH}/js/vendors/jquery/plugins/fancybox/helpers/jquery.fancybox-buttons.css`,
  },
  {
    href: `${DO_RESOURCE_PATH}/js/vendors/jquery/plugins/fancybox/helpers/jquery.fancybox-thumbs.css`,
  },
  {
    href: `${DO_RESOURCE_PATH}/js/vendors/jquery/plugins/fancybox/jquery.fancybox.css`,
  },
  {
    href: `${DO_RESOURCE_PATH}/js/vendors/jquery/plugins/tooltipster/tooltipster.min.css`,
  },
  { href: `${DO_RESOURCE_PATH}/js/vendors/simplebar/simplebar.css` },
  { href: `${DO_RESOURCE_PATH}/js/vendors/gridStack/gridstack.min.css` },
  { href: `${DO_RESOURCE_PATH}/js/vendors/realgrid/realgrid-style.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_customize.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_customize_print.css`, media: 'print' },
];

const ADMIN_STYLES = [
  { href: `${DO_RESOURCE_PATH}/css/fonts/notosans.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_admin_body.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_prototype.css` },
  { href: `${DO_RESOURCE_PATH}/css/ta_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/adm_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/doc_editor.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_admin_customize.css` },
  { href: `${DO_RESOURCE_PATH}/css/prefix.css` },
  { href: `${DO_RESOURCE_PATH}/js/vendors/simplebar/simplebar.css` },
];

const MOBILE_STYLE = [
  { href: `${DO_RESOURCE_PATH}/css/go_m_style.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_m_customize.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_m_style_big.css` },
  { href: `${DO_RESOURCE_PATH}/css/go_m_style_bigger.css` },
  { href: `${DO_RESOURCE_PATH}/css/doc_editor.css` },
  { href: `${DO_RESOURCE_PATH}/js/vendors/gridStack/gridstack.min.css` },
];

const loadDO = (styles: Array<Style>) => {
  // if (process.env.NODE_ENV === 'development') {
  //   login().catch((e) => console.log(e));
  // }
  preLoadDOResources(styles);

  loadScript({
    src: '/gw/resources/dist/app/form_editor_main.js',
  });
};

let isInit = false;
export function DaouOffice({ isAdmin = false, isMobile = false }: Props) {
  console.log('init daouoffice');
  useEffect(() => {
    console.log(`isInit: ${isInit}`);
    if (isInit) return;
    isInit = true;
    loadDO(isAdmin ? ADMIN_STYLES : isMobile ? MOBILE_STYLE : USER_STYLES);
  });

  return <></>;
}

export default DaouOffice;
