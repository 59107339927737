'use client';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../shared/lib/i18n/client';
import { NoData } from '../../../shared/ui/svg/no-data';
import { adContentsQuery, viewCountMutation } from '../api';
import {
  AdContentsInfo,
  DisplayTargetType,
  PortalType,
  PositionType,
} from '../types';

export interface Props {
  siteUuid: string;
  position: PositionType;
  displayTarget: DisplayTargetType;
  portal: PortalType;
  className?: string;
}

export function AdContents({
  siteUuid,
  position,
  displayTarget,
  portal,
  className,
}: Props) {
  const { t } = useTranslation('component');
  const { data: adInfo, error } = useSuspenseQuery({
    queryKey: [adContentsQuery.QUEREY_KEY],
    queryFn: () =>
      adContentsQuery.getAdContents(siteUuid, position, displayTarget, portal),
  });
  const [adContentInfo, setAdContentInfo] = useState<AdContentsInfo | null>(
    adInfo?.contents?.[0] ?? null,
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const adContentsViewCountMutation = useMutation({
    mutationKey: [viewCountMutation.MUTATION_KEY],
    mutationFn: () =>
      viewCountMutation.viewCountMutation(adContentInfo?.imgKey),
  });

  const onClickAdContentHandler = () => {
    adContentsViewCountMutation.mutate();
    window.open(adContentInfo?.linkUrl, '_blank');
  };

  useEffect(() => {
    if (adInfo?.contents && adInfo?.contents?.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % adInfo.contents.length,
        );
      }, adInfo.rollingTime * 10000);
      return () => clearInterval(interval);
    }
  }, [adInfo]);

  useEffect(() => {
    if (adInfo?.contents) {
      setAdContentInfo(adInfo?.contents?.[currentIndex] ?? null);
    }
  }, [currentIndex, adInfo]);

  const errorBlock = (
    <div className="items-center justify-center flex flex-col gap-[8px] text-base text-[--dop-color-coolgray-80] bg-[#e8eaed] py-[32px]">
      <NoData />
      {t('feature.AdContents.noData')}
    </div>
  );

  if (error) {
    return errorBlock;
  }

  return adContentInfo?.imgUrl ? (
    <button
      className={clsx(`${className ?? ''} w-full relative`)}
      onClick={onClickAdContentHandler}
      style={{
        border: 'none',
        padding: 0,
        background: adContentInfo.backColor,
      }}
      aria-label="Ad content"
    >
      <img
        className={clsx({
          'rounded-[8px]':
            portal === 'EMPLOYEE_PORTAL' || portal === 'BUSINESS_PORTAL',
        })}
        alt=""
        src={`/api/thumb/attach/original/doms/${adContentInfo?.imgUrl}`}
        style={{
          display: 'block',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundColor: adContentInfo?.backColor ?? 'transparent',
          backgroundSize: 'cover',
        }}
      />
    </button>
  ) : (
    errorBlock
  );
}
