import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Subtract 아이콘
 * @param props
 * @returns
 */
export function Subtract(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.47692 5.71651C8.47692 5.30867 8.1463 4.97805 7.73846 4.97805C7.33062 4.97805 7 5.30867 7 5.71651V8.79344C7 9.20128 7.33062 9.5319 7.73846 9.5319C8.1463 9.5319 8.47692 9.20128 8.47692 8.79344V5.71651ZM7.73846 10.5165C7.33062 10.5165 7 10.8471 7 11.255V11.2615C7 11.6694 7.33062 12 7.73846 12H7.74503C8.15287 12 8.48349 11.6694 8.48349 11.2615V11.255C8.48349 10.8471 8.15287 10.5165 7.74503 10.5165H7.73846Z"
        fill="#EE2E2E"
      />
    </IconContainer>
  );
}

export default Subtract;
