import {
  LOGIN_PAGE_URL,
  HttpStatusCodes,
  GRAPHQL_ENDPOINT,
  ServiceErrorCodes,
  TFA_PAGE_URL,
} from '../../constants';
import reqGatewayApi from './reqGatewayApi';

interface ResponseBody {
  code: string;
  error?: Error;
  message?: string;
  data?: unknown;
}

const isMobile = (): boolean => {
  const { userAgent } = window.navigator;
  return /android|iPad|iPhone|iPod/.test(userAgent);
};

export async function fetch(
  url: string,
  requestInit?: RequestInit,
): Promise<Response> {
  const resp = await reqGatewayApi(url, requestInit);

  if (resp.ok) {
    return resp;
  }

  const resultData = (await resp.json()) as ResponseBody;
  const nextURL = window.location.pathname;

  // 경영업무 포털 진입 시 2차인증이 안되어있는 경우 2차인증 페이지로 이동
  if (
    resp.status === HttpStatusCodes.UNAUTHORIZED &&
    resultData.code === ServiceErrorCodes['ROUTE-0900'].code
  ) {
    window.location.href = TFA_PAGE_URL + `?nextUrl=${nextURL}`;
  }

  if (resp.status === HttpStatusCodes.UNAUTHORIZED && !isMobile()) {
    console.info(`[Info] 액세스 토큰이 만료되어 리다이렉트.`);
    window.location.href = LOGIN_PAGE_URL + `?nextUrl=${nextURL}`;
  }

  return new Response(JSON.stringify(resultData), {
    status: resp.status,
    headers: resp.headers,
    statusText: resp.statusText,
  });
}

export interface QueryParameters<T> {
  query: string;
  operationName?: string;
  variables?: T | { [key: string]: string };
}

export async function fetchGraphQL<T>({
  query,
  operationName,
  variables,
}: QueryParameters<T>) {
  return fetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query, variables: variables ?? {}, operationName }),
  });
}

// legacy
export const withAuth = fetch;

export default fetch;
