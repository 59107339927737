import { useAtomValue } from 'jotai';
import {
  APP_MANAGEMENT_MENU_CODE,
  APP_MANAGEMENT_MENU_PATH,
  getMainMenuInfos,
  getMenuInfoWithPath,
  getSubMenuInfos,
} from '../../../menus';
import { appCodesAtom } from '../../../store/appCodesAtom';
import { Sub } from './sub';

export interface Props {}

export function Menu() {
  const appCodes = useAtomValue(appCodesAtom);
  const isAppManageMenuExist = appCodes
    ? appCodes.business.length > 0 || appCodes.employee.length > 0
    : false;
  const managementAppMenuInfo = getMainMenuInfos([
    ...appCodes.setting.map((menu) => menu.appCode),
    isAppManageMenuExist ? APP_MANAGEMENT_MENU_CODE : '',
  ]);

  return (
    <>
      {managementAppMenuInfo
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((menuInfo, index) => (
          <div key={`${menuInfo.menuCode}-${index}`} className="flex">
            <div className="flex flex-col h-full min-w-[240px] pb-[40px] overflow-auto">
              <div className="flex items-center p-[8px] px-[20px]">
                {menuInfo.icon}
                <p className="ml-[8px] text-[--color-text-level1] text-base font-[500]">
                  {menuInfo.name}
                </p>
              </div>
              {getMenuInfoWithPath(menuInfo.menuPath) && (
                <Sub
                  mainMenuPath={menuInfo.menuPath}
                  isAppManageMenu={
                    menuInfo.menuPath === APP_MANAGEMENT_MENU_PATH
                  }
                  subMenuInfos={getSubMenuInfos(
                    menuInfo.menuPath,
                    menuInfo.menuPath === APP_MANAGEMENT_MENU_PATH
                      ? [
                          ...appCodes.employee.map((menu) => menu.appCode),
                          ...appCodes.business.map((menu) => menu.appCode),
                        ]
                      : undefined,
                  )}
                />
              )}
            </div>
            {index !== managementAppMenuInfo.length - 1 && (
              <div className="border-l border-l-solid h-auto flex-grow" />
            )}
          </div>
        ))}
    </>
  );
}
