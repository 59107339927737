import { BaseResponseWithPage } from '../../../../GlobalConfig/pages/app-management/types';
import type { AppType } from '../types';

export interface OriginWorksType {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  icon: string;
  favoriteFlag: boolean;
  desc: string;
  showDescription: boolean;
  admin: boolean;
}

export const QUERY_KEY = 'organizer-works-list';

export interface WorksLinkPlusListType {
  nextPage: number;
  hasNext: boolean;
  appPageList: AppType[];
}

export async function getWorksList({
  page,
  offset,
}: {
  page: number;
  offset: number;
}): Promise<WorksLinkPlusListType> {
  try {
    const query = new URLSearchParams();
    query.append('page', page.toString());
    query.append('offset', offset.toString());

    const response = await fetch(
      `/gw/api/works/applets/favorites/paging?` + query.toString(),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getMailGroupDetail 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponseWithPage<OriginWorksType>;

    return adapter(resData);
  } catch (error) {
    console.log('getWorksList 오류 발생', error);
    return {
      appPageList: [],
      nextPage: page + 1,
      hasNext: false,
    };
  }
}

function adapter(
  source: BaseResponseWithPage<OriginWorksType>,
): WorksLinkPlusListType {
  return {
    nextPage: source.page.page + 1,
    hasNext: !source.page.lastPage,
    appPageList: source.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        thumbnail: `/gw/resources/images/applet/${data.icon}`,
        desc: data.desc,
        size: 'large',
      };
    }),
  };
}
