import { MessagePayload } from 'firebase/messaging';
import Link from 'next/link';
import { Avatar } from '../../../shared/ui/avatar';

export interface Props {
  notificationInfo?: MessagePayload;
}

export function ForegroundNotification({ notificationInfo }: Props) {
  return (
    <Link
      className="flex items-center gap-[20px]"
      href={notificationInfo?.data?.link ?? ''}
    >
      <Avatar size={40} src={notificationInfo?.notification?.image} alt={''} />
      <div className="flex flex-col gap-[4px] text-[--dop-color-white]">
        <h3 className="text-sm font-[500]">
          {notificationInfo?.notification?.title}
        </h3>
        <span className="text-2xs font-[500]">
          {notificationInfo?.notification?.body}
        </span>
      </div>
    </Link>
  );
}
