import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * UserMaster 아이콘
 * @param props
 * @returns
 */
export function UserMasterIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64124 3.64124C9.53204 2.75044 10.7402 2.25 12 2.25C13.2598 2.25 14.468 2.75044 15.3588 3.64124C16.2496 4.53204 16.75 5.74022 16.75 7C16.75 8.25978 16.2496 9.46796 15.3588 10.3588C14.468 11.2496 13.2598 11.75 12 11.75C10.7402 11.75 9.53204 11.2496 8.64124 10.3588C7.75044 9.46796 7.25 8.25978 7.25 7C7.25 5.74022 7.75044 4.53204 8.64124 3.64124ZM12 3.75C11.138 3.75 10.3114 4.09241 9.7019 4.7019C9.09241 5.3114 8.75 6.13805 8.75 7C8.75 7.86195 9.09241 8.6886 9.7019 9.2981C10.3114 9.90759 11.138 10.25 12 10.25C12.862 10.25 13.6886 9.90759 14.2981 9.2981C14.9076 8.6886 15.25 7.86195 15.25 7C15.25 6.13805 14.9076 5.3114 14.2981 4.7019C13.6886 4.09241 12.862 3.75 12 3.75ZM10 15.75C9.13805 15.75 8.3114 16.0924 7.7019 16.7019C7.09241 17.3114 6.75 18.138 6.75 19V21C6.75 21.4142 6.41421 21.75 6 21.75C5.58579 21.75 5.25 21.4142 5.25 21V19C5.25 17.7402 5.75044 16.532 6.64124 15.6412C7.53204 14.7504 8.74022 14.25 10 14.25H11.5C11.9142 14.25 12.25 14.5858 12.25 15C12.25 15.4142 11.9142 15.75 11.5 15.75H10Z"
        fill="currentColor"
      />
      <path
        d="M14 15C14 13.8954 14.8954 13 16 13H21C22.1046 13 23 13.8954 23 15V20C23 21.1046 22.1046 22 21 22H16C14.8954 22 14 21.1046 14 20V15Z"
        fill="currentColor"
      />
      <path
        d="M15.8271 15.0508H17.0986L18.4727 18.4072H18.5273L19.9014 15.0508H21.1729V20H20.1748V16.7598H20.1338L18.8418 19.9795H18.1582L16.8662 16.7461H16.8252V20H15.8271V15.0508Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default UserMasterIcon;
