import { useTranslation } from '../../../../shared/lib/i18n/client';
import { DashboardProps } from '../../types';
import { useDashboardContext } from '../../context';
import Tab from './tab';
import HeaderContainer from './container';
import { PlusIcon } from '@dop-ui/icons/react/dop/24';
import { IconButton } from '../../../../shared/ui/button';

export function TabContainer() {
  const { t } = useTranslation();
  const context = useDashboardContext();
  const { gadgets, dashboards, currentDashboard } = context.state;
  const {
    setCurrentDashboard,
    setEditMode,
    setGadgets,
    setOriginGadgets,
    setPrevDashboard,
  } = context.actions;
  const isDashboardAddable = dashboards.length < 5;

  const snapshot = () => {
    setOriginGadgets(gadgets);
    setPrevDashboard(currentDashboard);
  };

  const handleEditDashboardClick = () => {
    snapshot();
    setEditMode(true);
  };

  const handleCreateDashboardClick = () => {
    const tempDashboardData = {
      dashboardId: -1,
      name: t('dashboard.privateDashboard'),
      layout: 1,
      activated: true,
      updatable: true,
      deletable: true,
    };
    snapshot();
    setGadgets([]);
    setCurrentDashboard(tempDashboardData);
    setEditMode(true);
  };

  return (
    <HeaderContainer>
      <ul className="h-full flex gap-x-4 w-[1768px] m-[0_auto] pl-2">
        {dashboards.map((dashboard: DashboardProps) => (
          <Tab
            key={`dashboard-${dashboard.dashboardId}`}
            name={dashboard.name}
            onClick={handleEditDashboardClick}
            dashboard={dashboard}
          />
        ))}

        {isDashboardAddable && (
          <li className="flex items-center">
            <IconButton
              className="!p-1"
              icon={PlusIcon}
              onClick={() => void handleCreateDashboardClick()}
            />
          </li>
        )}
      </ul>
    </HeaderContainer>
  );
}

export default TabContainer;
