import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as boardPaths from './paths';
import * as appManagementPages from '../../../pages/app-management';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/board';
export const menuKeyCode = 'dop-employee-board';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.board"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.board.globalBoard"
          />
        ),
        menuPath: boardPaths.GLOBAL_BOARD_PATH,
        sortOrder: 0,
        page: <appManagementPages.GlobalBoardStatusPage />,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.board.allBoardStatus"
          />
        ),
        menuPath: boardPaths.ALL_BOARD_STATUS_PATH,
        sortOrder: 1,
        page: <appManagementPages.AllBoardStatusPage />,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.board.departmentBoardStatus"
          />
        ),
        menuPath: boardPaths.DEPARTMENT_BOARD_STATUS_PATH,
        sortOrder: 2,
        page: <appManagementPages.DepartmentBoardStatusPage />,
      },
    ],
  },
];
