import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 경영업무포털 GNB 접고 펴기 버튼
 * @param props
 * @returns
 */
export function FoldingIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15L17.5 5C17.5 3.61929 16.3807 2.5 15 2.5L8 2.5L8 17.5L15 17.5ZM18.5 15C18.5 16.933 16.933 18.5 15 18.5L5 18.5C3.067 18.5 1.5 16.933 1.5 15L1.5 5C1.5 3.067 3.06701 1.5 5 1.5L15 1.5C16.933 1.5 18.5 3.06701 18.5 5L18.5 15ZM5 17.5L7 17.5L7 2.5L5 2.5C3.61929 2.5 2.5 3.61929 2.5 5L2.5 15C2.5 16.3807 3.61929 17.5 5 17.5Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default FoldingIcon;
