import { useSetAtom } from 'jotai';
import { currentSelectedMenuInfoAtom } from '../../../../store/currentSelectedMenuInfoAtom';
import { openSubMenusAtom } from '../../../../store/openSubMenusAtom';
import { selectedMainCategoryAtom } from '../../../../store/selectedMainCategoryAtom';
import { serviceMapOpenStateAtom } from '../../../../store/serviceMapOpenStateAtom';
import { MenuInfo } from '../../../../types';

export interface Props {
  mainMenuPath: string;
  parentMenuPath?: string;
  menuInfo: MenuInfo;
}

export function SubMenuItem({ mainMenuPath, parentMenuPath, menuInfo }: Props) {
  const setSelectedCurrentMenu = useSetAtom(currentSelectedMenuInfoAtom);
  const setSelectedMainCategory = useSetAtom(selectedMainCategoryAtom);
  const setOpenSubMenus = useSetAtom(openSubMenusAtom);
  const setServiceMapOpenState = useSetAtom(serviceMapOpenStateAtom);
  const isLeaf =
    menuInfo.children === undefined || menuInfo.children.length === 0;

  const onClickHandler = () => {
    setSelectedMainCategory(mainMenuPath);
    setOpenSubMenus((prev) => {
      const newOpenSubMenus = prev;
      if (parentMenuPath) {
        newOpenSubMenus.add(parentMenuPath);
      }
      return newOpenSubMenus;
    });

    setSelectedCurrentMenu({
      menuCode: menuInfo.menuCode,
      menuPath: menuInfo.menuPath,
    });
    setServiceMapOpenState(false);
  };

  return !isLeaf ? (
    <li className="ps-[32px] flex flex-col">
      <span className="p-[4px] text-sm text-[#0F1419] min-w-[168px]">
        {menuInfo.name}
      </span>
      <div className="flex flex-col pt-[8px] pb-[16px]">
        {menuInfo.children?.map((childMenuInfo, index) => (
          <SubMenuItem
            mainMenuPath={mainMenuPath}
            key={`${childMenuInfo.menuCode}-${childMenuInfo.menuPath}-${String(childMenuInfo.name)}-${index}`}
            parentMenuPath={menuInfo.menuPath}
            menuInfo={childMenuInfo}
          />
        ))}
      </div>
    </li>
  ) : (
    <button
      className="text-sm p-[4px] w-full text-[#8d9193] hover:bg-[#dddddd50] text-left rounded-[8px] min-w-[168px]"
      onClick={onClickHandler}
    >
      {menuInfo.name}
    </button>
  );
}

export default SubMenuItem;
