import(/* webpackMode: "eager", webpackExports: ["OrganizerProvider"] */ "/tmp/app/apps/dop-employee-portal/components/Providers/OrganizerProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/pages/employee/layout/root.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/dop-ui-root-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/next-app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FCMProvider"] */ "/tmp/app/packages/ui-react/widgets/fcm/ui/index.tsx");
