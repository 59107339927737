import { useContext } from '../ui/context';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  fetchNotificationList,
  NOTI_LIST_QUERY_KEY,
} from '../apis/notification';
import { NotificationItemData } from '../types';

export const useNotificationList = () => {
  const { target, currentCategory, setCurrentDetailId } = useContext();

  const pageSize = 30;

  const {
    data,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    error,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [NOTI_LIST_QUERY_KEY, target, currentCategory],
    queryFn: ({ pageParam }) =>
      fetchNotificationList(pageParam, pageSize, target, currentCategory),
    gcTime: 0,
    initialPageParam: 0,
    getNextPageParam: (data) => {
      if (!data) return undefined;

      const hasNext = data.page.pageNumber < data.page.totalPages - 1;

      return hasNext ? data.page.pageNumber + 1 : undefined;
    },
  });

  const notificationList =
    data?.pages
      .flatMap((page) => page?.elements)
      .filter((data) => data !== undefined) ?? [];

  const onClickNotification = (notification: NotificationItemData) => {
    setCurrentDetailId(notification.id);
  };

  const onClickFetchNext = () => {
    if (!hasNextPage) return;
    void fetchNextPage();
  };

  return {
    notificationList,
    fetchNextPage: onClickFetchNext,
    isLoading,
    isFetchingNextPage,
    error,
    onClickNotification,
  };
};
