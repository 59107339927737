import { clsx } from 'clsx';
import { MenuInfo } from '../../../../types';
import { SubMenuItem } from './sub-menu-item';

export interface Props {
  mainMenuPath: string;
  subMenuInfos: MenuInfo[];
  isAppManageMenu?: boolean;
}

export function Sub({ mainMenuPath, subMenuInfos, isAppManageMenu }: Props) {
  return (
    <ul
      className={clsx(
        'flex pt-[12px] overflow-auto h-full justify-start mb-[48px] px-[20px]',
        {
          'flex-col': !isAppManageMenu,
          'flex-wrap': isAppManageMenu,
        },
      )}
    >
      {subMenuInfos.map((subMenuInfo) => (
        <SubMenuItem
          key={`${subMenuInfo.menuCode}-${subMenuInfo.menuPath}`}
          mainMenuPath={mainMenuPath}
          menuInfo={subMenuInfo}
        />
      ))}
    </ul>
  );
}

export default Sub;
