import { clsx } from 'clsx';
import { formatDate } from '../../../../../shared/lib/date';
import { useStackMethod } from '../../../../../shared/ui/stacker';
import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';
import { NotificationItemData } from '../../../types';

import styles from './styles.module.css';

interface Props {
  isLast?: boolean;
  notificationItem: NotificationItemData;
  onClickNotification: (data: NotificationItemData) => void;
}

export function NotificationItem({
  isLast = false,
  notificationItem,
  onClickNotification,
}: Props) {
  const { t } = useTranslation('component');

  const { push } = useStackMethod();

  const showNewBadge = () => {
    const today = new Date();
    const startDate = new Date(notificationItem.startDate);

    const diffInMilliseconds = Math.abs(today.getTime() - startDate.getTime());

    const seventyTwoHoursInMilliseconds = 72 * 60 * 60 * 1000;

    return diffInMilliseconds <= seventyTwoHoursInMilliseconds;
  };

  return (
    <div
      className={clsx(styles.ItemContainer, {
        [`${styles.notLast}`]: !isLast,
      })}
      role="button"
      onClick={() => {
        push('notification-detail');
        onClickNotification(notificationItem);
      }}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <div className={styles.ItemHeader}>
        <div className={styles.ItemCategory}>
          {t(`customerNoti.category.${notificationItem.categoryType}`)}
        </div>
        <div className="flex-grow" />
        <p className={styles.ItemDate}>
          {formatDate({
            date: notificationItem.startDate,
            format: 'YYYY-MM-DD',
          })}
        </p>
      </div>
      <p className={styles.ItemTitle}>
        {notificationItem.title}
        {showNewBadge() && <span className={styles.NewBadge}>N</span>}
      </p>
    </div>
  );
}
