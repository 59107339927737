import * as React from 'react';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import { IconContainer } from '@daouoffice/ui/lib/icons/dop/IconContainer';

export interface Props {
  // className?: string;
  icon: typeof IconContainer;
  label?: string;
}

export const FuncButton = React.forwardRef<HTMLDivElement, Props>(
  ({ icon: Icon, label }: Props, ref) => {
    const btnEl = (
      <div role="button" className="btn_icon medium" ref={ref}>
        <Icon />
      </div>
    );
    // 버튼 컴포넌트를 다시 만들기 전까지 임시로 다음 코드 사용
    return label ? (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{btnEl}</Tooltip.Trigger>

        {/* Portal을 사용하지 않음. GNB의 z-index가 1로 되어 있어서 Portal을 사용할 경우 GNB 뒤에 가려짐 */}
        {/* 추후 z-index 정리 필요 */}
        <Tooltip.Content align="center" side="right" sideOffset={5}>
          {label}
        </Tooltip.Content>
      </Tooltip.Root>
    ) : (
      btnEl
    );
  },
);

FuncButton.displayName = 'FuncButton';

export default FuncButton;
