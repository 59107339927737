import AppItem from './app-item';
import { ScrollList } from './scroll/scroll-list';
import styles from '../shortcut.module.css';
import { NoWorksTemplate } from './no-data-template/no-works-template';
import { NoLinkPlusTemplate } from './no-data-template/no-link-plus-template';
import { useSuspenseQuery } from '@tanstack/react-query';
import * as getWorksList from '../api/getWorksList';
import * as getLinkPlusList from '../api/getLinkPlusList';

interface Props {
  type: string;
  scrollable?: boolean;
  offset?: number;
}

export function AppList({ type, scrollable = false, offset = 5 }: Props) {
  const isWorks = type === 'WORKS';

  const {
    data: { appPageList },
    error,
  } = useSuspenseQuery({
    queryKey: [isWorks ? getWorksList.QUERY_KEY : getLinkPlusList.QUERY_KEY],
    queryFn: async () =>
      isWorks
        ? await getWorksList.getWorksList({ page: 0, offset })
        : await getLinkPlusList.getLinkPlusList({ page: 0, offset }),
  });

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  if (appPageList && appPageList.length === 0) {
    return isWorks ? <NoWorksTemplate /> : <NoLinkPlusTemplate />;
  }

  return (
    <ul id={type} className={styles.AppList}>
      {scrollable ? (
        <ScrollList type={type} offset={offset} />
      ) : (
        appPageList.map((appItem) => (
          <AppItem key={appItem.id} type={type} appItem={appItem} />
        ))
      )}
    </ul>
  );
}

export default AppList;
