import clsx from 'clsx';
import { ReactElement } from 'react';

interface Props {
  className?: string;
  content: string | ReactElement;
  hidden?: boolean;
}

export function ManageFixedGadget({
  className,
  content,
  hidden = false,
}: Props) {
  return (
    <>
      {hidden ? (
        <></>
      ) : (
        <div className={clsx('min-h-[100px] rounded-[8px]', className)}>
          {content}
        </div>
      )}
    </>
  );
}
