import { ReactElement } from 'react';
import styles from './styles.module.css';

interface Props {
  title: string;
  description: string | ReactElement;
}
export function MessageDialogContentWithTitle({ title, description }: Props) {
  return (
    <div className={styles.Dialog}>
      <p className={styles.DialogTitle}>{title}</p>
      <p className={styles.DialogDescription}>{description}</p>
    </div>
  );
}

export default MessageDialogContentWithTitle;
