import { useDashboardContext } from '../../../context';
import { useEffect } from 'react';
import { readGadgets } from '../../../apis';
import { ManageGadgetColumn } from './manage-gadget-column';

interface Props {
  isEditMode: boolean;
}

export function ManageGadgetBoard({ isEditMode }: Props) {
  const context = useDashboardContext();
  const { currentDashboard } = context.state;
  const { setOriginGadgets, setGadgets } = context.actions;

  useEffect(() => {
    const getGadgetData = () => {
      if (!currentDashboard) return;
      if (currentDashboard.dashboardId === -1) return;
      if (isEditMode) return;

      readGadgets(currentDashboard.dashboardId)
        .then((gadgetData) => {
          setGadgets(gadgetData.gadgetList);
          setOriginGadgets(gadgetData.gadgetList);
        })
        .catch((e) => console.error(e));
    };
    getGadgetData();
  }, [currentDashboard, setOriginGadgets, setGadgets]);

  if (!currentDashboard) return;

  return (
    <div
      className={`grid grid-cols-3 grid-flow-row !overflow-hidden gap-10 ${isEditMode ? 'go-dashboard-editing' : ''}`}
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <ManageGadgetColumn
          key={index}
          column={index + 1}
          section={index}
          isEditMode={isEditMode}
          dashboardId={currentDashboard.dashboardId}
        />
      ))}
    </div>
  );
}
