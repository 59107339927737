import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { RectDotsIcon } from '../../../../../../ui-icons/react/dop/24/RectDots';
import {
  APP_MANAGEMENT_MENU_CODE,
  FAVORITE_MENU_CODE,
  getMainMenuInfos,
  getMyMenuInfos,
} from '../../../menus';
import { favoriteMenuAtom } from '../../../store/favoriteMenuAtom';
import { selectedMainCategoryAtom } from '../../../store/selectedMainCategoryAtom';
import { serviceMapOpenStateAtom } from '../../../store/serviceMapOpenStateAtom';
import { AuthorizedAppCode } from '../../../types';
import { MainMenuList } from './MenuList';
import { SearchBar } from './SearchBar';

export interface Props {
  settingMenus: AuthorizedAppCode[];
  isAppManageMenuExist: boolean;
}

export function Main({ settingMenus, isAppManageMenuExist }: Props) {
  const { t } = useTranslation('component');
  const favoriteMenuInfos = useAtomValue(favoriteMenuAtom);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const setSelectedMainCategory = useSetAtom(selectedMainCategoryAtom);
  const [, setServiceMapOpenState] = useAtom(serviceMapOpenStateAtom);

  const myMenuInfo = getMyMenuInfos();
  const managementAppMenuInfo = getMainMenuInfos([
    ...settingMenus.map((menu) => menu.appCode),
    isAppManageMenuExist ? APP_MANAGEMENT_MENU_CODE : '',
  ]);

  useEffect(() => setFirstLoaded(true), []);

  useEffect(() => {
    if (firstLoaded && managementAppMenuInfo.length > 0) {
      setSelectedMainCategory(managementAppMenuInfo.at(0)?.menuPath ?? '');
      setFirstLoaded(false);
    }
  }, [firstLoaded, managementAppMenuInfo, setSelectedMainCategory]);

  return (
    <div className="pt-[50px] w-[240px] h-full px-[24px] border-r-[1px] flex-shrink-0 group/menu">
      <h2 className="px-[8px] text-[#3B3B3B] text-[24px] font-[600]">
        {t('globalconfig.common.dialog.title')}
      </h2>
      <SearchBar className="mt-[16px]" />
      <button
        className="flex items-center mt-[24px] pl-[8px] w-full bg-transparent text-[#3B3B3B]"
        // TODO : ServiceMap Style 및 기능 추가 후, 기능 구현
        onClick={() => setServiceMapOpenState(true)}
      >
        <RectDotsIcon />
        <span className="pl-[8px] text-[#3B3B3B]">
          {t('globalconfig.common.menu.title')}
        </span>
      </button>
      <div className="mt-[16px] mx-[8px] h-[1px] w-full bg-[#E5E5E5]" />
      <div className="px-[12px] py-[4px] mt-[16px] text-[#888888]">My</div>
      <MainMenuList.Root className="mt-[4px]">
        {myMenuInfo.map((menuInfo) => (
          <MainMenuList.Item
            key={menuInfo.menuPath}
            menuPath={menuInfo.menuPath}
          >
            {menuInfo.icon}
            <p className="ml-[8px] text-[--color-text-level1] text-[14px] font-[500]">
              {menuInfo.name}
            </p>
            {menuInfo.menuCode === FAVORITE_MENU_CODE && (
              <p className="ml-[4px] px-[6px] py-[2px] h-[18px] bg-[#08A7BF] rounded-full text-center text-white text-[11px]">
                {favoriteMenuInfos?.length ?? 0}
              </p>
            )}
            <span className="spacer flex-grow" />
            <ChevronRightIcon className="size-[16px]" color={'#AAAAAA'} />
          </MainMenuList.Item>
        ))}
      </MainMenuList.Root>
      <div className="px-[12px] py-[4px] mt-[16px] text-[#888888]">
        Management
      </div>
      <MainMenuList.Root className="mt-[4px]">
        {managementAppMenuInfo
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((menuInfo) => (
            <MainMenuList.Item
              key={menuInfo.menuPath}
              menuPath={menuInfo.menuPath}
            >
              {menuInfo.icon}
              <p className="ml-[8px] text-[--color-text-level1] text-[14px] font-[500]">
                {menuInfo.name}
              </p>
            </MainMenuList.Item>
          ))}
      </MainMenuList.Root>
    </div>
  );
}
