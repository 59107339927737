export interface MenuInfo {
  id: string;
  name: string;
  path: string;
}

const MAX_MENU_HISTORY = 5;
const MENU_HISTORY_KEY = 'recentMenuHistory';

export function saveRecentMenu(menu: MenuInfo): void {
  const existingData = localStorage.getItem(MENU_HISTORY_KEY);
  const menuHistory: MenuInfo[] = existingData
    ? (JSON.parse(existingData) as MenuInfo[])
    : [];

  const updatedHistory = menuHistory.filter((item) => item.id !== menu.id);

  updatedHistory.unshift(menu);

  if (updatedHistory.length > MAX_MENU_HISTORY) {
    updatedHistory.pop();
  }

  localStorage.setItem(MENU_HISTORY_KEY, JSON.stringify(updatedHistory));
}

export function getRecentMenuList(): MenuInfo[] {
  const existingData = localStorage.getItem(MENU_HISTORY_KEY);
  return existingData ? (JSON.parse(existingData) as MenuInfo[]) : [];
}

export function deleteRecentMenuItem(id: string) {
  const existingData = localStorage.getItem(MENU_HISTORY_KEY);
  const recentMenuItem = existingData
    ? (JSON.parse(existingData) as MenuInfo[])
    : [];

  const changedRecentMenuList = recentMenuItem.filter((item) => item.id !== id);
  localStorage.setItem(MENU_HISTORY_KEY, JSON.stringify(changedRecentMenuList));
}
