'use client';

import * as React from 'react';
import { clsx } from 'clsx';
import * as Dialog from '../../dialog';
import { Button } from '../../button';
import { useTranslation } from '../../../lib/i18n/client';
import type { Props as MessageDialogProps } from './message-dialog';
import { MESSAGE_DIALOG_SIZE, MESSAGE_DIALOG_VARIANT } from '../contants';
import type {
  MessageDialogType,
  MessageDialogSizeType,
  MessageDialogVariantType,
} from '../types';

import style from './style.module.css';

export interface Props
  extends Omit<MessageDialogProps, 'open' | 'onOpenChange'> {
  type: MessageDialogType;
  size?: MessageDialogSizeType;
  variant?: MessageDialogVariantType;
  asChild?: boolean;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export function MessageDialogContent({
  children,
  type,
  size = MESSAGE_DIALOG_SIZE.MEDIUM,
  variant = MESSAGE_DIALOG_VARIANT.NORMAL,
  asChild,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslation('component');

  return (
    <Dialog.Content
      position="center"
      animation="popup"
      showCloseButton={false}
      usePortal
      useOverlay
      useAutoClose={false}
      className={clsx(style.MessageDialog, {
        [`${style.small}`]: size === MESSAGE_DIALOG_SIZE.SMALL,
        [`${style.medium}`]: size === MESSAGE_DIALOG_SIZE.MEDIUM,
        [`${style.large}`]: size === MESSAGE_DIALOG_SIZE.LARGE,
        [`${style.normal}`]: variant === MESSAGE_DIALOG_VARIANT.NORMAL,
        [`${style.critical}`]: variant === MESSAGE_DIALOG_VARIANT.CRITICAL,
      })}
    >
      {/** 메시지 본문 */}
      <Dialog.Description
        className={clsx(style.MessageDialog__Body)}
        asChild={asChild}
      >
        {children}
      </Dialog.Description>

      {/** 버튼 */}
      <div className={style.MessageDialog__Footer}>
        {type === 'confirm' && (
          <Button
            onClick={onCancel}
            size="medium"
            shape="rect"
            variant="ghost"
            colorset="minor"
          >
            {cancelText ?? t('widget.MessageDialog.cancel')}
          </Button>
        )}
        <Button
          onClick={onConfirm}
          size="medium"
          shape="rect"
          variant="solid"
          colorset={
            variant === MESSAGE_DIALOG_VARIANT.CRITICAL ? 'critical' : 'level1'
          }
        >
          {confirmText ?? t('widget.MessageDialog.confirm')}
        </Button>
      </div>
    </Dialog.Content>
  );
}

export default MessageDialogContent;
