import * as Stacker from '../../../../shared/ui/stacker';
import { useTranslation } from '../../../../shared/lib/i18n/client/use-translation';
import { useContextValue } from '../context';
import { NotificationList } from './notification-list';
import { NotificationDetail } from './notification-detail';

import styles from './styles.module.css';

export function Content() {
  const { t } = useTranslation('component');

  const { currentCategory } = useContextValue();

  return (
    <div className={styles.ContentContainer}>
      <div className={styles.Header}>
        {t(`customerNoti.category.${currentCategory}`)}
      </div>
      <Stacker.Root defaultPage="notification-list">
        <Stacker.Content
          className={styles.StackContainer}
          name="notification-list"
        >
          <NotificationList />
        </Stacker.Content>
        <Stacker.Content
          className={styles.StackContainer}
          name="notification-detail"
        >
          <NotificationDetail />
        </Stacker.Content>
      </Stacker.Root>
    </div>
  );
}
