import * as React from 'react';
import { Context } from './context';
import { MessageDialog } from './message-dialog';
import type { MessageDialogType, MessageDialogOptions } from '../types';

interface MessageDialogState extends MessageDialogOptions {
  type: MessageDialogType;
  open: boolean;
  message: string | React.ReactElement | null;
  resolve: ((value: boolean) => void) | null;
}

export function Provider({ children }: React.PropsWithChildren) {
  const [dialogState, setDialogState] = React.useState<MessageDialogState>({
    type: null,
    size: 'medium',
    variant: 'normal',
    open: false,
    message: null,
    resolve: null,
  });

  const confirm = React.useCallback(
    (message: string | React.ReactElement, options?: MessageDialogOptions) => {
      return new Promise<boolean>((resolve) => {
        const {
          size = 'medium',
          variant = 'normal',
          cancelText,
          confirmText,
        } = options || {};
        setDialogState({
          type: 'confirm',
          size,
          variant,
          open: true,
          message,
          resolve,
          cancelText,
          confirmText,
        });
      });
    },
    [],
  );

  const alert = React.useCallback(
    (message: string | React.ReactElement, options?: MessageDialogOptions) => {
      return new Promise<boolean>((resolve) => {
        const {
          size = 'medium',
          variant = 'normal',
          confirmText,
        } = options || {};
        setDialogState({
          type: 'alert',
          size,
          variant,
          open: true,
          message,
          resolve,
          confirmText,
        });
      });
    },
    [],
  );

  const close = () => {
    setDialogState({
      type: null,
      open: false,
      message: null,
      resolve: null,
      size: null,
      variant: null,
      cancelText: undefined,
      confirmText: undefined,
    });
  };

  const handleResolve = React.useCallback(() => {
    if (dialogState.resolve) {
      dialogState.resolve(true);
      close();
    }
  }, [dialogState]);

  const handleReject = React.useCallback(() => {
    if (dialogState.resolve) {
      dialogState.resolve(false);
      close();
    }
  }, [dialogState]);

  const ctxVal = {
    alert,
    confirm,
  };

  const isTextMessage = typeof dialogState.message === 'string';
  const dialogMessage = dialogState.message;

  return (
    <Context value={ctxVal}>
      {children}

      {dialogState.type && (
        <MessageDialog
          type={dialogState.type}
          open={dialogState.open}
          size={dialogState.size}
          variant={dialogState.variant}
          onOpenChange={() => close()}
          cancelText={dialogState.cancelText}
          confirmText={dialogState.confirmText}
          onConfirm={handleResolve}
          onCancel={handleReject}
          asChild={!isTextMessage}
        >
          {dialogMessage}
        </MessageDialog>
      )}
    </Context>
  );
}

export default Provider;
