import { useSuspenseQuery } from '@tanstack/react-query';
import { useContextValue } from '../ui/context';
import { fetchCategoryList } from '../apis/category-list';

export const useCategoryList = () => {
  const { target } = useContextValue();

  const {
    data: categoryList,
    isLoading,
    error,
  } = useSuspenseQuery({
    queryKey: ['QueryKeys.CustomerNotification.CategoryList', target],
    queryFn: () => fetchCategoryList(target),
  });

  return { categoryList: categoryList ?? [], isLoading, error };
};
