import { keywordSearchedResult } from '@dop-ui/react/shared/lib/search';
import { IconClockHour3, IconSearch, IconX } from '@tabler/icons-react';
import { clsx } from 'clsx';
import { useState } from 'react';
import { deleteRecentMenuItem, getRecentMenuList, MenuInfo } from '../../utils';
import { useActionsContext } from '../Context';
import { MapAppInfo } from '../types';

export interface Props {
  data: MapAppInfo[];
}

export function SearchBar({ data }: Props) {
  const { t } = useActionsContext();
  const [isOpened, setOpenState] = useState(false);
  const [keyword, setKeyword] = useState('');
  const relatedKeywordMenuList = keywordSearchedResult<MapAppInfo>(
    data,
    keyword,
    ['name'],
  );
  const [recentMenuList, setRecentMenuList] =
    useState<MenuInfo[]>(getRecentMenuList());

  const handleOnFocus = () => {
    setOpenState(true);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenState(false);
    }
  };

  const handleOnDeleteRecentMenuItem = (id: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setRecentMenuList((prev) => prev.filter((menu) => menu.id !== id));
    deleteRecentMenuItem(id);
  };

  return (
    <div
      className={clsx('wrap_search', isOpened ? 'open !rounded-[12px]' : '')}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      tabIndex={-1}
    >
      <div className={clsx('group_input', isOpened ? 'focused' : '')}>
        <label className="label blind" htmlFor="test_search">
          {t('serviceMap.searchBar.label.searchMenu')}
        </label>
        <div className="input_search !px-[16px]">
          <IconSearch size={13} color="#2F3645" />
          <input
            className="input_txt"
            id="test_search"
            type="search"
            title={t('serviceMap.searchBar.title')}
            placeholder={t('serviceMap.searchBar.placeholder')}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button className="btn_icon clear">
            {t('serviceMap.searchBar.label.refresh')}
          </button>
        </div>
      </div>
      <div className="search_list">
        {keyword ? (
          relatedKeywordMenuList.map((appInfo) => (
            <a
              key={appInfo.uid}
              className="flex items-center gap-[16px] px-[16px] py-[12px] hover:bg-[#F5F6F8]"
              href={appInfo.url}
            >
              <div
                className="size-[48px] flex items-center justify-center app_icon border border-solid border-[#E6E7EA] p-[12px] rounded-[16px]"
                dangerouslySetInnerHTML={{ __html: appInfo.icon }}
              />
              <div className="flex flex-col gap-[2px]">
                <span className="text-base text-[#262626] font-[500]">
                  {appInfo.name}
                </span>
                <span className="text-xs text-[#888] font-[400]">
                  {appInfo.description}
                </span>
              </div>
            </a>
          ))
        ) : (
          <>
            {recentMenuList.length > 0 && (
              <span className="text-[#9B9C9E] text-2xs font-[500] w-full px-[16px] py-[8px]">
                {t('serviceMap.searchBar.label.recentSearchList')}
              </span>
            )}
            {recentMenuList.map((menuInfo) => (
              <a
                href={menuInfo.path}
                key={menuInfo.id}
                className="flex items-center justify-between px-[16px] py-[8px] gap-[8px] text-sm font-[400] text-[#1C1C1C] hover:bg-[#F5F6F8]"
              >
                <div className="flex items-center gap-[8px]">
                  <IconClockHour3 size={16} color="#C5C7CA" />
                  {menuInfo.name}
                </div>
                <IconX
                  size={16}
                  color="#1C1C1C"
                  tabIndex={0}
                  onClick={(e) => handleOnDeleteRecentMenuItem(menuInfo.id, e)}
                />
              </a>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
