import { ChevronDownIcon } from '@dop-ui/icons/react/dop/24';
import { Button } from '../../../../../../shared/ui/button';
import { useTranslation } from '../../../../../../shared/lib/i18n/client/use-translation';
import { Icon } from '../../../../../../shared/ui/button/primitives';
import HeaderContainer from '../../../header/container';
import React from 'react';
import { useDashboardContext } from '../../../../context';
import * as apis from '../../../../apis';
import { ManageCancelDialog } from './manage-cancel-dialog';
import { CheckIcon } from '@dop-ui/icons/react/dop/16';

interface Props {
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleGadgetSpecs: (bool: boolean) => void;
  isVisibleGadgetSpecs: boolean;
}

export function ManageControlToolbar({
  setEditMode,
  toggleGadgetSpecs = () => undefined,
  isVisibleGadgetSpecs = false,
}: Props) {
  const { t } = useTranslation('component');
  const context = useDashboardContext();
  const { currentDashboard, gadgets, originGadgets } = context.state;
  const { editDashboard, setOriginGadgets, setGadgets } = context.actions;
  if (!setEditMode) return;

  const updateDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.updateDashboard({
      dashboardId: currentDashboard.dashboardId,
      name: currentDashboard.name,
      layout: currentDashboard.layout,
      gadgetList: gadgets,
    });
    const updatedDashboard = { ...currentDashboard, ...resp };
    editDashboard(updatedDashboard);
    clear();
    setEditMode(false);
  };

  const handleSaveClick = async () => {
    if (!currentDashboard) return;
    try {
      if (currentDashboard.dashboardId === -1) {
        // await createDashboard();
        return;
      } else {
        await updateDashboard();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const reset = () => {
    setGadgets(originGadgets);
    setEditMode(false);
  };

  const clear = () => {
    setOriginGadgets([]);
  };

  return (
    <HeaderContainer>
      <div className="flex flex-row justify-end w-full">
        <div className="flex items-center gap-x-1">
          <ManageCancelDialog onReset={reset} />
          <Button
            className="flex gap-1"
            onClick={() => void handleSaveClick()}
            size="md"
            shape="round"
            variant="outline"
            colorset="level2"
          >
            <CheckIcon size={20} />
            {t('dashboard.save')}
          </Button>
          <Button
            title={t('dashboard.gadget.list.view')}
            onClick={() => toggleGadgetSpecs(!isVisibleGadgetSpecs)}
            size="md"
            shape="round"
            variant="ghost"
            colorset="level1"
          >
            <Icon>
              <ChevronDownIcon
                className={isVisibleGadgetSpecs ? 'rotate-180' : undefined}
              />
            </Icon>
          </Button>
        </div>
      </div>
    </HeaderContainer>
  );
}
