import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 우산(휴가) 아이콘
 * @param props
 * @returns
 */
export function UmbrellaIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <g>
        <path
          d="M12.8725 12.675L10.4494 16.8719M17.2669 3.9126C15.2146 5.16484 11.0636 8.44022 10.8786 11.5238M18.2638 4.48815C18.251 6.91791 17.5673 12.1951 14.9347 13.8657M1.7998 19.1896L4.33326 18.075C5.8813 17.394 7.58698 17.1523 9.26327 17.3766L9.34085 17.387C11.0429 17.6147 12.6475 18.3137 13.9733 19.4052L15.9998 21.0735M6.66602 9.09171L19.0789 16.2583C19.781 16.6636 20.6787 16.4231 21.084 15.721C23.3809 11.7427 22.0179 6.65554 18.0395 4.35863L17.4911 4.04204C13.5128 1.74513 8.42565 3.10822 6.12874 7.08658C5.72341 7.78864 5.96395 8.68637 6.66602 9.09171Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </IconContainer>
  );
}

export default UmbrellaIcon;
