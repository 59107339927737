import { NoData } from '../../../../../shared/ui/svg/no-data';
import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';
import { useNotificationList } from '../../../model/use-notification-list';
import { NotificationItem } from './notification-item';

import styles from './styles.module.css';
import containerStyles from '../styles.module.css';

export function NotificationList() {
  const { t } = useTranslation('component');

  const {
    notificationList,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
    onClickNotification,
  } = useNotificationList();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLElement;

    if (scrollHeight - scrollTop - clientHeight < 100 && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  if (error)
    return (
      <div
        className={containerStyles.NotificationContainer}
        onScroll={handleScroll}
      >
        <div className={containerStyles.NotificationContentContainer}>
          <div className={styles.AlertContainer}>
            <NoData />
            <p className={styles.AlertMessage}>{error.message}</p>
          </div>
        </div>
      </div>
    );

  if (isLoading)
    return (
      <div
        className={containerStyles.NotificationContainer}
        onScroll={handleScroll}
      >
        <div className={containerStyles.NotificationContentContainer}>
          <div className={styles.AlertContainer}>
            <p className={styles.AlertMessage}>...Loading</p>
          </div>
        </div>
      </div>
    );

  if (notificationList.length === 0)
    return (
      <div
        className={containerStyles.NotificationContainer}
        onScroll={handleScroll}
      >
        <div className={containerStyles.NotificationContentContainer}>
          <div className={styles.AlertContainer}>
            <NoData />
            <p className={styles.AlertMessage}>
              {t('customerNoti.list.empty')}
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={containerStyles.NotificationContainer}
      onScroll={handleScroll}
    >
      <div className={containerStyles.NotificationContentContainer}>
        <div className={styles.ListContainer}>
          {notificationList.map((notificationItem, index) => (
            <NotificationItem
              key={notificationItem.id}
              isLast={index === notificationList.length - 1}
              notificationItem={notificationItem}
              onClickNotification={onClickNotification}
            />
          ))}
          {isFetchingNextPage && (
            <p className={styles.LoadingMessage}>...Loading</p>
          )}
        </div>
      </div>
    </div>
  );
}
