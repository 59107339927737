import { fetch } from '../../../shared/lib/fetch/client';
import {
  BaseResponse,
  NotificationTarget,
  NotificationCategory,
} from '../types';

const API_URL = '/api/portal/common/announcement/category';

export async function fetchCategoryList(target: NotificationTarget) {
  const resp = await fetch(`${API_URL}/${target}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData = (await resp.json()) as BaseResponse<{
    elements: NotificationCategory[];
  }>;

  if (resp.ok) {
    return responseData.data?.elements ?? [];
  }
  throw new Error(responseData.message);
}
