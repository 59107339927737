import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Speaker 아이콘
 * @param props
 * @returns
 */
export function SpeakerIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.85 4.42278V19.5772L7.38638 15.1136C7.21759 14.9448 6.98867 14.85 6.74998 14.85H4.50998C3.94475 14.85 3.54383 14.5328 3.43965 14.1569C3.2467 13.4545 3.14927 12.7293 3.14998 12.0009C3.14998 11.2523 3.25092 10.5289 3.43963 9.84311C3.54365 9.46672 3.94404 9.14999 4.50998 9.14999H6.74998C6.98867 9.14999 7.21759 9.05516 7.38638 8.88638L11.85 4.42278ZM11.6785 2.44227C11.9984 2.37872 12.3299 2.4114 12.6312 2.53616C12.9325 2.66093 13.1901 2.87219 13.3714 3.14326C13.5528 3.41434 13.6497 3.73306 13.65 4.05919V19.94C13.6497 20.2661 13.5528 20.5856 13.3714 20.8567C13.1901 21.1278 12.9325 21.339 12.6312 21.4638C12.3299 21.5886 11.9984 21.6212 11.6785 21.5577C11.3586 21.4942 11.0647 21.3372 10.834 21.1068L6.37719 16.65H4.50998C3.31551 16.65 2.06855 15.9535 1.70448 14.6356L1.70414 14.6344C1.46828 13.776 1.34915 12.8898 1.34998 11.9995C1.35002 11.0883 1.47305 10.2052 1.70425 9.36516C2.06835 8.04567 3.31606 7.34999 4.50998 7.34999H6.37719L10.8336 2.89359C11.0643 2.66313 11.3586 2.50582 11.6785 2.44227ZM18.4776 4.99959C18.829 4.64812 19.3989 4.64812 19.7504 4.99959C20.6697 5.91889 21.3989 7.01026 21.8965 8.21139C22.394 9.41252 22.6501 10.6999 22.6501 12C22.6501 13.3001 22.394 14.5874 21.8965 15.7886C21.3989 16.9897 20.6697 18.0811 19.7504 19.0004C19.3989 19.3519 18.829 19.3519 18.4776 19.0004C18.1261 18.6489 18.1261 18.0791 18.4776 17.7276C19.2298 16.9754 19.8264 16.0825 20.2335 15.0997C20.6405 14.117 20.8501 13.0637 20.8501 12C20.8501 10.9363 20.6405 9.88297 20.2335 8.90023C19.8264 7.91748 19.2298 7.02454 18.4776 6.27239C18.1261 5.92092 18.1261 5.35107 18.4776 4.99959ZM15.8266 7.65154C16.1781 7.30009 16.748 7.30014 17.0994 7.65164C18.2526 8.80496 18.9004 10.3691 18.9004 12C18.9004 13.6309 18.2526 15.195 17.0994 16.3483C16.748 16.6998 16.1781 16.6999 15.8266 16.3484C15.4751 15.997 15.4751 15.4271 15.8265 15.0756C16.6422 14.2599 17.1004 13.1536 17.1004 12C17.1004 10.8464 16.6422 9.74009 15.8265 8.92433C15.4751 8.57283 15.4751 8.00298 15.8266 7.65154Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default SpeakerIcon;
