import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as approvalPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/approval';
export const menuKeyCode = 'dop-employee-approval';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: <I18nText namespace={i18nNamespace} i18nKey="전자결재" />,
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="결재 양식 관리" />,
        menuPath: approvalPaths.FORM_PATH,
        sortOrder: 0,
        iframeSrc: '/gw/iframe/admin/approval',
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText namespace={i18nNamespace} i18nKey="전자결재 기본설정" />
        ),
        menuPath: approvalPaths.CONFIG_PATH,
        sortOrder: 1,
        iframeSrc: '/gw/iframe/admin/approval/config',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="결재 운영자 설정" />,
        menuPath: approvalPaths.MANAGER_PATH,
        sortOrder: 2,
        iframeSrc: '/gw/iframe/admin/approval/manager',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="자동 결재선" />,
        menuPath: approvalPaths.APPR_LINE_RULE_PATH,
        sortOrder: 3,
        iframeSrc: '/gw/iframe/admin/approval/apprlinerule',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="부서 문서함" />,
        menuPath: approvalPaths.DEPT_FOLDER_PATH,
        sortOrder: 4,
        iframeSrc: '/gw/iframe/admin/approval/deptfolder',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="공문 설정" />,
        menuPath: approvalPaths.MANAGE_OFFICIAL_PATH,
        sortOrder: 5,
        iframeSrc: '/gw/iframe/admin/approval/manage/official',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="결재 문서 번호" />,
        menuPath: approvalPaths.CONFIG_DOCNUMBER_PATH,
        sortOrder: 6,
        iframeSrc: '/gw/iframe/admin/approval/config/docnum',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="보안 등급" />,
        menuPath: approvalPaths.SECURITY_LEVEL_PATH,
        sortOrder: 7,
        iframeSrc: '/gw/iframe/admin/approval/securitylevel',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="서명 일괄 등록" />,
        menuPath: approvalPaths.CONFIFG_SIGN_REGIST_PATH,
        sortOrder: 8,
        iframeSrc: '/gw/iframe/admin/approval/config/sign/regist',
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText namespace={i18nNamespace} i18nKey="멤버 결재 환경 설정" />
        ),
        menuPath: approvalPaths.DEPT_PATH,
        sortOrder: 9,
        iframeSrc: '/gw/iframe/admin/dept',
      },
      {
        menuCode: menuKeyCode,
        name: <I18nText namespace={i18nNamespace} i18nKey="결재 문서 현황" />,
        menuPath: approvalPaths.MANAGER_DOCUMENT_PATH,
        sortOrder: 10,
        iframeSrc: '/gw/iframe/admin/approval/manage/document',
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText namespace={i18nNamespace} i18nKey="전자결재 일자별 통계" />
        ),
        menuPath: approvalPaths.STATISTICS_PATH,
        sortOrder: 11,
        iframeSrc: '/gw/iframe/admin/approval/statistics',
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText namespace={i18nNamespace} i18nKey="전자결재 부서별 통계" />
        ),
        menuPath: approvalPaths.DEPT_STATISTICS_PATH,
        sortOrder: 12,
        iframeSrc: '/gw/iframe/admin/approval/deptstatistics',
      },
    ],
  },
];
