import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import * as dataManagementPages from '../../pages/dataManagement';
import { MenuInfo } from '../../types';
import { codes, paths } from '../main';
import * as dataManagementPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.DATA_MANAGEMENT;

export const menuInfo: MenuInfo[] = [
  {
    menuCode: codes.DATA_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.dataManagement.usage"
      />
    ),
    menuPath: dataManagementPaths.USAGE_PATH,
    sortOrder: 0,
    children: [
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.usage.current"
          />
        ),
        menuPath: dataManagementPaths.USAGE_CURRENT_PATH,
        sortOrder: 0,
        page: <dataManagementPages.CurrentDataUsagePage />,
      },
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.usage.request"
          />
        ),
        menuPath: dataManagementPaths.USAGE_REQUEST_PATH,
        sortOrder: 1,
        dependencies: ['dop-employee-mail', 'dop-employee-webfolder'],
        page: <dataManagementPages.DataRequestManagementPage />,
      },
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.usage.notification"
          />
        ),
        menuPath: dataManagementPaths.USAGE_NOTIFICATION_PATH,
        sortOrder: 2,
        page: <dataManagementPages.SetUsageNotificationPage />,
      },
    ],
  },
  {
    menuCode: codes.DATA_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.dataManagement.uploadPolicy"
      />
    ),
    menuPath: dataManagementPaths.UPLOAD_POLICY_PATH,
    sortOrder: 1,
    children: [
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.uploadPolicy.manage"
          />
        ),
        menuPath: dataManagementPaths.UPLOAD_POLICY_MANAGE_PATH,
        sortOrder: 0,
        page: <dataManagementPages.UploadPolicyManagementPage />,
      },
    ],
  },
  {
    menuCode: codes.DATA_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.dataManagement.uploadData"
      />
    ),
    menuPath: dataManagementPaths.UPLOAD_DATA_PATH,
    sortOrder: 2,
    children: [
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.uploadData.manage"
          />
        ),
        menuPath: dataManagementPaths.UPLOAD_DATA_MANAGE_PATH,
        sortOrder: 0,
        page: <dataManagementPages.UploadDataManagementPage />,
      },
    ],
    dependencies: ['dop-default-chat'],
  },
  {
    menuCode: codes.DATA_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.dataManagement.dept"
      />
    ),
    menuPath: dataManagementPaths.DEPTS_PATH,
    sortOrder: 3,
    children: [
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.dept.data"
          />
        ),
        menuPath: dataManagementPaths.DEPTS_DATA_PATH,
        sortOrder: 0,
        page: <dataManagementPages.DeptManagementPage />,
      },
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.dept.delete"
          />
        ),
        menuPath: dataManagementPaths.DEPTS_DELETE_PATH,
        sortOrder: 1,
        page: <dataManagementPages.DeletedDeptManagementPage />,
      },
    ],
    dependencies: [
      'dop-employee-approval',
      'dop-employee-board',
      'dop-employee-report',
      'dop-employee-contact',
    ],
  },
  {
    menuCode: codes.DATA_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.dataManagement.member"
      />
    ),
    menuPath: dataManagementPaths.MEMBER_PATH,
    sortOrder: 4,
    children: [
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.member.data"
          />
        ),
        menuPath: dataManagementPaths.MEMBER_DATA_PATH,
        sortOrder: 0,
        page: <dataManagementPages.MemberManagementPage />,
      },
    ],
    dependencies: ['dop-employee-mail', 'dop-employee-webfolder'],
  },
  {
    menuCode: codes.DATA_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.dataManagement.backup"
      />
    ),
    menuPath: dataManagementPaths.BACKUP_PATH,
    sortOrder: 5,
    children: [
      {
        menuCode: codes.DATA_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.dataManagement.backup.manage"
          />
        ),
        menuPath: dataManagementPaths.BACKUP_DATA_PATH,
        sortOrder: 0,
        page: <dataManagementPages.BackUpManagementPage />,
      },
    ],
  },
];
