import { Button } from '@dop-ui/react/shared/ui/button';
import { IconBriefcase } from '@tabler/icons-react';

export function ExternalInterWorkingCenter() {
  const handleOnClick = () => {
    window.dispatchEvent(new CustomEvent('eacc:ui:InterworkingCenter:open'));
  };

  return (
    <Button onClick={handleOnClick} as="div">
      <IconBriefcase stroke={1.5} />
    </Button>
  );
}

export default ExternalInterWorkingCenter;
