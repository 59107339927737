import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 비행기 아이콘
 * @param props
 * @returns
 */
export function AirplaneIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M2.8775 15.3975L6.69434 17.3059L8.60277 21.1228L10.5112 19.2144V16.3517L13.8509 13.012L16.7136 21.5999L19.5762 18.7373L17.1907 9.67225L21.0075 5.85541C21.798 5.06491 21.798 3.78327 21.0075 2.99277C20.217 2.20228 18.9354 2.20228 18.1449 2.99277L14.328 6.80962L5.26303 4.42409L2.40039 7.28673L10.9883 10.1494L7.64855 13.4891H4.78592L2.8775 15.3975Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default AirplaneIcon;
