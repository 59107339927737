import { AppList } from './app-list';

interface Props {
  scrollable: boolean;
}
export function WorksList({ scrollable }: Props) {
  return (
    <AppList
      type="WORKS"
      scrollable={scrollable}
      offset={scrollable ? 20 : 5}
    />
  );
}

export default WorksList;
