import { MenuInfo } from '../../types';
import { paths } from '../main';
import * as mailMenuInfo from './mail';
import * as approvalMenuInfo from './approval';
import * as docsMenuInfo from './docs';
import * as surveyMenuInfo from './survey';
import * as reportMenuInfo from './report';
import * as communityMenuInfo from './community';
import * as hrMenuInfo from './mp-hr';
import * as econtractMenuInfo from './finance';
import * as financeMenuInfo from './e-contract';
import * as payrollMenuInfo from './payroll';
import * as tempMenuInfo from './mp-temp';
import * as expensesMenuInfo from './expenses';
import * as worksMenuInfo from './works';
import * as messengerInfo from './messenger';
import * as boardMenuInfo from './board';
import * as companyFolderInfo from './company-folder';
import * as contactInfo from './contact';
import * as webfolderInfo from './webfolder';
import * as homeInfo from './home';
import * as calendarInfo from './calendar';
import * as assetInfo from './asset';
import * as linkplusInfo from './linkplus';
import * as mpHomeInfo from './mp-home';

export const menuKeyPath = paths.APPS_MANAGEMENT;

export const wholeMenuInfoMap: Map<string, MenuInfo[]> = new Map([
  [mailMenuInfo.menuKeyCode, mailMenuInfo.menuInfo],
  [approvalMenuInfo.menuKeyCode, approvalMenuInfo.menuInfo],
  [docsMenuInfo.menuKeyCode, docsMenuInfo.menuInfo],
  [surveyMenuInfo.menuKeyCode, surveyMenuInfo.menuInfo],
  [reportMenuInfo.menuKeyCode, reportMenuInfo.menuInfo],
  [communityMenuInfo.menuKeyCode, communityMenuInfo.menuInfo],
  [boardMenuInfo.menuKeyCode, boardMenuInfo.menuInfo],
  [hrMenuInfo.menuKeyCode, hrMenuInfo.menuInfo],
  [econtractMenuInfo.menuKeyCode, econtractMenuInfo.menuInfo],
  [financeMenuInfo.menuKeyCode, financeMenuInfo.menuInfo],
  [payrollMenuInfo.menuKeyCode, payrollMenuInfo.menuInfo],
  [tempMenuInfo.menuKeyCode, tempMenuInfo.menuInfo],
  [expensesMenuInfo.menuKeyCode, expensesMenuInfo.menuInfo],
  [worksMenuInfo.menuKeyCode, worksMenuInfo.menuInfo],
  [messengerInfo.menuKeyCode, messengerInfo.menuInfo],
  [companyFolderInfo.menuKeyCode, companyFolderInfo.menuInfo],
  [contactInfo.menuKeyCode, contactInfo.menuInfo],
  [webfolderInfo.menuKeyCode, webfolderInfo.menuInfo],
  [homeInfo.menuKeyCode, homeInfo.menuInfo],
  [calendarInfo.menuKeyCode, calendarInfo.menuInfo],
  [assetInfo.menuKeyCode, assetInfo.menuInfo],
  [linkplusInfo.menuKeyCode, linkplusInfo.menuInfo],
  [mpHomeInfo.menuKeyCode, mpHomeInfo.menuInfo],
]);

export const getAppManagementMenuInfo = (
  menuKeyCodes: string[],
): MenuInfo[] => {
  return menuKeyCodes
    .map((code) => wholeMenuInfoMap.get(code))
    .filter((menuInfo) => menuInfo !== undefined)
    .flat()
    .sort((a, b) => a.sortOrder - b.sortOrder);
};
