import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_APP_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(FIREBASE_CONFIG);

export const useFCM = () => {
  const messaging = getMessaging(firebaseApp);

  const getFCMToken = async () => {
    try {
      const fcmToken = await getToken(messaging, {
        vapidKey: process.env.NEXT_PUBLIC_APP_FIREBASE_VAPID_KEY,
      });
      return fcmToken;
    } catch (error) {
      throw new Error((error as { message: string }).message);
    }
  };

  return {
    messaging,
    getFCMToken,
  };
};
