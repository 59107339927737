import { useInView } from 'react-intersection-observer';
import { useInfiniteScrollQuery } from './use-infinite-scroll-query';
import { useEffect } from 'react';
import AppItem from '../app-item';
import styles from '../../shortcut.module.css';
import { NoWorksTemplate } from '../no-data-template/no-works-template';

interface Props {
  type: string;
  offset: number;
}

export function ScrollList({ type, offset }: Props) {
  const {
    scrollList,
    getNextPage,
    isScrollSuccess,
    isHasNextPage,
    isError,
    isLoading,
    isFetching,
  } = useInfiniteScrollQuery({ type, offset });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && isHasNextPage) {
      void getNextPage();
    }
  }, [inView]);

  if (isLoading) {
    return <div>Loading ... </div>;
  }

  if (isError) {
    return <></>;
  }

  if (!scrollList || scrollList.length === 0) {
    return <NoWorksTemplate />;
  }

  return (
    <ul id={type} className={styles.AppList}>
      {isScrollSuccess &&
        scrollList &&
        scrollList.map((appItem) => (
          <AppItem key={appItem.id} type={type} appItem={appItem} />
        ))}
      {isHasNextPage && !isFetching && <div ref={ref} />}
    </ul>
  );
}

export default ScrollList;
