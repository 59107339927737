import { MessagePayload, onMessage } from 'firebase/messaging';
import { useState } from 'react';
import { savePushToken } from '../apis/token';
import { PushDevice, PushType } from '../types';
import { useFCM } from './use-fcm';
import { useNotificationPermission } from './use-notification-permission';

export const usePushNotification = (device: PushDevice, pushType: PushType) => {
  const { messaging, getFCMToken } = useFCM();
  const { checkNotificationPermission } = useNotificationPermission();
  const [fcmToken, setFcmToken] = useState<string | undefined>();
  const [foregroundNotificationInfo, setForegroundNotificationInfo] = useState<
    MessagePayload | undefined
  >();

  const initNotification = async () => {
    const isPermissionGranted = await checkNotificationPermission();

    if (!isPermissionGranted) {
      console.error('Notification permission is not granted');
      return;
    }

    try {
      const newFcmToken = await getFCMToken();

      if (!newFcmToken) {
        console.error(
          'No registration token available. Request permission to generate one.',
        );
        return;
      }

      setFcmToken(newFcmToken);

      await savePushToken({
        pushDevice: device,
        pushType,
        pushToken: newFcmToken,
      });

      console.log('Token saved successfully');

      // 포그라운드 메시지 수신
      onMessage(messaging, (payload: MessagePayload) => {
        console.log('Foreground Message Received', payload);
        setForegroundNotificationInfo(payload);
      });

      // 백그라운드 메시지 수신
      const registInit = async () => {
        const registration = await navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: './',
          },
        );
        registration.waiting?.postMessage('SKIP_WAITING');
      };

      'serviceWorker' in navigator && (await registInit());
    } catch (error) {
      console.error(error);
    }
  };

  const deInitNotification = () => {
    // TODO: Implement deInitNotification
  };

  return {
    fcmToken,
    initNotification,
    deInitNotification,
    foregroundNotificationInfo,
  };
};
