import * as React from 'react';
import * as Dialog from '../../foundation/Dialog';
import { AppList, AppListItem } from './AppList';
import { ContentContainer } from './ContentContainer';
import { useContext as useServiceMapContext } from './Context';
import type { MapAppInfo } from './types';

export interface Props {
  /** @property 내부 앱 전체 정보 */
  data: MapAppInfo[];
  usePortal?: boolean;
}

/**
 * 서비스맵 컨텐츠 영역
 */
export function Content({
  data,
  usePortal = true,
}: React.PropsWithChildren<Props>) {
  const { t, isUsedOnly, serviceType } = useServiceMapContext();

  const { empData, mngData } = data.reduce(
    (result, item) => {
      if (item.service === 'ep') {
        result.empData.push(item);
      } else if (item.service === 'mp') {
        result.mngData.push(item);
      }
      return result;
    },
    { empData: Array<MapAppInfo>(), mngData: Array<MapAppInfo>() },
  );

  return (
    <Dialog.Content size="full" autoHeight={isUsedOnly} isPortal={usePortal}>
      <ContentContainer data={data}>
        <AppList
          title={t('serivceMap.employee.sectionTitle')}
          data={empData}
          direction="left"
        >
          {(appInfo) => {
            return (
              <AppListItem
                key={appInfo.uid}
                uuid={appInfo.uid}
                link={appInfo.url}
                name={appInfo.name}
                description={appInfo.description}
                appStatus={appInfo.status}
                icon={appInfo.icon}
                targetBlank={serviceType === 'mp'}
              />
            );
          }}
        </AppList>
        <AppList
          title={t('serivceMap.management.sectionTitle')}
          data={mngData}
          direction="center"
        >
          {(appInfo) => {
            return (
              <AppListItem
                key={appInfo.uid}
                uuid={appInfo.uid}
                link={appInfo.url}
                name={appInfo.name}
                description={appInfo.description}
                appStatus={appInfo.status}
                icon={appInfo.icon}
                targetBlank={serviceType === 'ep'}
              />
            );
          }}
        </AppList>
      </ContentContainer>
    </Dialog.Content>
  );
}

export default Content;
