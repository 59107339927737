'use client';

import { PropsWithChildren } from 'react';
import { IconX } from '@tabler/icons-react';
import * as Dialog from '../../../shared/ui/dialog';
import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';
import { NotificationTarget } from '../types';
import { Provider } from './context';
import { SideMenu } from './side-menu';

import styles from './styles.module.css';
import { Content } from './content';

interface Props {
  target: NotificationTarget;
}

export function CustomerNotificationDialog({
  target,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('component');
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Title>{t('customerNoti.title')}</Dialog.Title>
      <Dialog.Content
        className={styles.Container}
        useOverlay
        usePortal
        useAutoClose
        showCloseButton={false}
      >
        <Dialog.Primitives.Close className={styles.CloseButton}>
          <IconX size={28} stroke={1.5} />
        </Dialog.Primitives.Close>
        <Provider target={target}>
          <SideMenu />
          <Content />
        </Provider>
      </Dialog.Content>
    </Dialog.Root>
  );
}
