import { MouseEvent, PropsWithChildren } from 'react';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import { clsx } from 'clsx';
import Link from 'next/link';
import { ShortcutAppType, ShortcutItemType } from '../types';
import styles from '../organizer-body.module.css';
export interface Props {
  id: string | number;
  name: string;
  onClick?: () => void;
  url?: string;
  className?: string;
  iconBgColor?: string;
  itemType?: ShortcutItemType;
  appType?: ShortcutAppType;
}

function LinkButton({
  className,
  id,
  name,
  url,
  itemType,
  iconBgColor,
  onClick,
  appType,
  children,
}: PropsWithChildren<Props>) {
  const handleOnClick = (
    event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (onClick) {
      event.preventDefault();
      void onClick();
    }

    if (!onClick && !url) {
      event.preventDefault();
    }
  };

  const generateUrl = () => {
    if (onClick || !url) return '';

    if (!itemType || itemType === 'APP') return url;

    return url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `http://${url}`;
  };

  const generateToolTipContent = (name: string, itemType?: string) => {
    if (!itemType) return name;
    const isLinkNameOverflow = itemType === 'LINK' && name.length > 22;

    return isLinkNameOverflow ? `${name.slice(0, 22)}...` : name;
  };

  const generateButton = () => {
    if (!appType && !url) {
      return (
        <button
          className={clsx('btn_icon', 'bg-gray-400')}
          onClick={handleOnClick}
        >
          <div style={{ backgroundColor: iconBgColor ?? 'transparent' }}>
            {children}
          </div>
        </button>
      );
    } else {
      const LinkTag = appType && appType === 'WORKS' ? Link : 'a';
      return (
        <LinkTag
          className={clsx('btn_icon', 'bg-gray-400')}
          onClick={handleOnClick}
          href={generateUrl()}
          key={id}
          target="_blank"
          rel="noreferrer"
        >
          <div style={{ backgroundColor: iconBgColor ?? 'transparent' }}>
            {children}
          </div>
        </LinkTag>
      );
    }
  };

  return (
    <li key={id} className={clsx(styles.link_list, className)}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{generateButton()}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content sideOffset={30} side="left">
            <p className="max-w-[120px] overflow-hidden whitespace-nowrap text-ellipsis">
              {generateToolTipContent(name, itemType)}
            </p>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </li>
  );
}
export default LinkButton;
