import(/* webpackMode: "eager", webpackExports: ["OrganizerProvider"] */ "/tmp/app/apps/dop-employee-portal/components/Providers/OrganizerProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/pages/employee/layout/root.css");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.69.5/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/dop-ui-root-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/next-app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FCMProvider"] */ "/tmp/app/packages/ui-react/widgets/fcm/ui/index.tsx");
