import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Excel 아이콘
 * @param props
 * @returns
 */
export function Excel(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="#4DA855"
      />
      <path
        d="M8.98748 7.99947L11.3215 5.23601C11.5908 4.91676 11.5509 4.44787 11.2318 4.17851C10.9126 3.90915 10.4438 3.94905 10.1745 4.2683L8 6.83223L5.82555 4.2683C5.55624 3.94905 5.08743 3.90915 4.76825 4.17851C4.44906 4.44787 4.40916 4.91676 4.67847 5.23601L7.01252 7.99947L4.67847 10.7629C4.40916 11.0822 4.44906 11.5511 4.76825 11.8204C4.90789 11.9401 5.07746 12 5.24702 12C5.45649 12 5.66596 11.9102 5.81557 11.7306L7.99003 9.1667L10.1645 11.7306C10.3141 11.9102 10.5236 12 10.733 12C10.9026 12 11.0722 11.9401 11.2118 11.8204C11.531 11.5511 11.5709 11.0822 11.3016 10.7629L8.96753 7.99947H8.98748Z"
        fill="white"
      />
    </IconContainer>
  );
}

export default Excel;
