import { Response } from '../types';
import { API_AD_CONTENTS_VIEW_COUNT_URL } from './constants';

export const MUTATION_KEY = 'viewCountMutation';

export async function viewCountMutation(contentKey?: number) {
  if (!contentKey) {
    throw new Error('');
  }

  const res = await fetch(
    `${API_AD_CONTENTS_VIEW_COUNT_URL}?contentKey=${contentKey}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!res.ok) {
    throw new Error(((await res.json()) as Response<boolean>).errorMessage);
  }
}
