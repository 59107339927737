import { PropsWithChildren } from 'react';

export function Contents({ children }: PropsWithChildren) {
  return (
    <div className="overflow-auto max-h-full flex pt-[16px] px-[36px]">
      {children}
    </div>
  );
}

export default Contents;
