import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getWorksList } from '../../api/getWorksList';
import { getLinkPlusList } from '../../api/getLinkPlusList';

interface Props {
  type: string;
  offset: number;
}

const QUERY_KEY = 'organizer-quickmenu-infinite-scroll';

export function useInfiniteScrollQuery({ type, offset }: Props) {
  const {
    data: appPageList,
    fetchNextPage: getNextPage,
    isSuccess: isScrollSuccess,
    hasNextPage: isHasNextPage,
    isError,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY, type],
    queryFn: ({ pageParam }) =>
      type === 'WORKS'
        ? getWorksList({ page: pageParam, offset })
        : getLinkPlusList({ page: pageParam, offset }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage.hasNext ? lastPage.nextPage : undefined;
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const scrollList = useMemo(() => {
    const list = appPageList?.pages.flatMap((item) => item.appPageList);
    return list;
  }, [appPageList]);

  return {
    scrollList,
    getNextPage,
    isScrollSuccess,
    isHasNextPage,
    isError,
    isLoading,
    isFetching,
  };
}
