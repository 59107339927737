import {
  AdInfo,
  DisplayTargetType,
  PortalType,
  PositionType,
  Response,
} from '../types';
import { API_AD_CONTENTS_URL } from './constants';

export const QUEREY_KEY = 'adContentsQuery';

export async function getAdContents(
  siteUuid: string,
  position: PositionType,
  displayTarget: DisplayTargetType,
  portal: PortalType,
) {
  const queryString = new URLSearchParams({
    siteUuid,
    position,
    displayTarget,
    portal,
  }).toString();

  const res = await fetch(`${API_AD_CONTENTS_URL}?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error(((await res.json()) as Response<AdInfo>).message);
  }

  const { data } = (await res.json()) as Response<AdInfo>;

  return data;
}
