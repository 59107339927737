'use client';

import { getGoAgent } from './ClientUtil';
import { GO_ANDROID, GO_IPHONE } from './Constants';
import { getCookie } from '../../../next/lib/cookie/client';

interface Webkit {
  messageHandlers: {
    [key: string]: {
      postMessage: (message: unknown) => void;
    };
  };
}

interface GOMobile {
  [key: string]: (data?: unknown) => void;
}

declare global {
  interface Window {
    webkit: Webkit;
    GOMobile: GOMobile;
  }
}

const GoRequest = () => {
  class CustomXMLHttpRequest extends XMLHttpRequest {
    private method: string = '';
    private url: string | URL = '';
    private body: XMLHttpRequestBodyInit | null = null;

    constructor() {
      super();

      this.open = this.customOpen.bind(this);
      this.send = this.customSend.bind(this);
      this.onreadystatechange = this.customOnReadyStateChange.bind(this);
    }

    private customOpen(
      method: string,
      url: string | URL,
      async: boolean = true,
      user?: string | null,
      password?: string | null,
    ): void {
      console.log('Request:', method, url);

      this.method = method;
      this.url = url;

      super.open(method, url, async, user, password);
    }

    private customSend(body: XMLHttpRequestBodyInit | null): void {
      this.body = body;
      super.send(body);
    }

    private customOnReadyStateChange(): void {
      if (this.readyState === this.DONE) {
        if (this.status === 401) {
          this.refreshToken()
            .then(() => {
              console.log('Token refreshed, retrying original request...');
              const newXHR = new CustomXMLHttpRequest();
              newXHR.open(this.method, this.url, true);
              newXHR.send(this.body);
            })
            .catch((error) => {
              console.log('Error refreshing token:', error);
              const goAgent = getGoAgent();
              if (goAgent === GO_ANDROID) {
                if (window.GOMobile.onAuthorizeFail)
                  window.GOMobile.onAuthorizeFail(error);
              } else if (goAgent === GO_IPHONE) {
                window.webkit?.messageHandlers?.onAuthorizeFail?.postMessage({
                  msg: error as unknown,
                });
              }
            });
        }
      }
    }

    private async refreshToken(): Promise<Response> {
      const response = await fetch('/api/portal/public/auth/refresh/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const goAgent = getGoAgent();
      if (!response.ok) {
        const resp = (await response.json()) as unknown;
        console.log(
          `[Bridge Call] GoRequest > onAuthorizeFail msg: ${JSON.stringify(resp)}`,
        );
        if (goAgent === GO_ANDROID) {
          if (window.GOMobile.onAuthorizeFail)
            window.GOMobile.onAuthorizeFail(JSON.stringify(resp));
        } else if (goAgent === GO_IPHONE) {
          window.webkit?.messageHandlers?.onAuthorizeFail?.postMessage({
            msg: resp,
          });
        }
        throw new Error('Failed to refresh token');
      } else {
        const accessToken = getCookie('AccessToken');
        console.log(
          `[Bridge Call] GoRequest > updateAccessToken accessToken: ${JSON.stringify(accessToken)}`,
        );
        if (goAgent === GO_ANDROID) {
          if (window.GOMobile.updateAccessToken)
            window.GOMobile.updateAccessToken(accessToken);
        } else if (goAgent === GO_IPHONE) {
          window.webkit?.messageHandlers?.updateAccessToken?.postMessage({
            accessToken,
          });
        }
      }

      return response;
    }
  }

  window.XMLHttpRequest =
    CustomXMLHttpRequest as unknown as typeof window.XMLHttpRequest;
  return <></>;
};

export default GoRequest;
