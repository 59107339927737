import { GadgetApiParams } from '../../../types';
import Gadget from '../../content/gadget';
import { useTranslation } from 'react-i18next';
interface Props {
  isEditMode: boolean;
  gadget: GadgetApiParams;
  seq: number;
  dashboardId: number;
}

export function ManageGadgetContainer({ isEditMode, gadget }: Props) {
  const { t } = useTranslation();
  const dashboardName = t('dashboard.name');
  return (
    <Gadget
      spec={gadget}
      isEditMode={isEditMode}
      dashboardName={dashboardName}
    />
  );
}
