import { fetch } from '../../../shared/lib/fetch/client';
import {
  BaseResponseWithPage,
  NotificationItemData,
  NotificationCategory,
  NotificationTarget,
  BaseResponse,
  FileInfo,
} from '../types';

const API_PREFIX = '/api/portal/common/announcement/customer';

const NOTI_LIST_API = `${API_PREFIX}/list`;

export const NOTI_LIST_QUERY_KEY =
  'QueryKeys.CustomerNotification.NotificationList';

export async function fetchNotificationList(
  page: number,
  size: number,
  target: NotificationTarget,
  category: NotificationCategory,
) {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append('page', page.toString());
  urlSearchParams.append('size', size.toString());
  if (category !== 'ALL')
    urlSearchParams.append('announcementCategoryType', category);
  const response = await fetch(
    `${NOTI_LIST_API}/${target}?${urlSearchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    },
  );

  const responseData =
    (await response.json()) as BaseResponseWithPage<NotificationItemData>;

  if (response.ok) {
    return responseData.data;
  }

  throw new Error(responseData.message);
}

export const NOTI_DETAIL_QUERY_KEY =
  'QueryKeys.CustomerNotification.NotificationDetail';

export async function fetchNotificationDetail(
  id: number,
  target: NotificationTarget,
) {
  const response = await fetch(`${API_PREFIX}/${target}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<NotificationItemData>;

  if (response.ok) {
    return responseData.data;
  }

  throw new Error(responseData.message);
}

const NOTI_FILE_DOWNLOAD_API = '/api/portal/common/basic/announcement/customer';

export async function downloadFile(notificationId: number, fileInfo: FileInfo) {
  const response = await fetch(
    `${NOTI_FILE_DOWNLOAD_API}/${notificationId}/file/${fileInfo.id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    },
  );

  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileInfo.name;
    a.click();
  } else {
    const data = (await response.json()) as BaseResponse<undefined>;
    throw new Error(data.message);
  }
}
