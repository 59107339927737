export const useNotificationPermission = () => {
  const permissionStatus = Notification.permission;

  const checkNotificationPermission = async () => {
    if (permissionStatus === 'granted') {
      return true;
    } else if (permissionStatus === 'denied') {
      return false;
    }

    const permissionResponse = await requestNotificationPermission();

    if (permissionResponse === 'granted') {
      return true;
    }
    return false;
  };

  const requestNotificationPermission = async () => {
    return await Notification.requestPermission();
  };

  return { checkNotificationPermission };
};
