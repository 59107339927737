'use client';

import { useSuspenseQuery } from '@tanstack/react-query';
import { usePathname, useParams, useSearchParams } from 'next/navigation';
import * as React from 'react';
import { useMenusAtom } from '../../store';
import * as userMenusQuery from '../api/userMenusQuery';
import { getAppHomeInfo } from '../getAppHomeInfo';
import AppsDockClientInner from './AppsDockClient';

export default function AppsDockClient() {
  const [menusAtom, setMenusAtom] = useMenusAtom();
  const params = useParams();
  const path = usePathname();
  const searchParams = useSearchParams();

  const { data: userApps, error } = useSuspenseQuery({
    queryKey: [userMenusQuery.QUERY_KEY],
    queryFn: async () => await userMenusQuery.getUserMenus(),
  });

  const getPathname = () => {
    const { pathname, hash } = new URL(window.location.href);
    const fullPath = `${pathname}${hash}`;
    const parts = fullPath.split('/').filter(Boolean);
    return '/' + parts.slice(0, 3).join('/');
  };

  const [pathname, setPathname] = React.useState(getPathname());

  React.useEffect(() => {
    setPathname(getPathname());
  }, [params, path, searchParams]);

  React.useEffect(() => {
    // 홈 메뉴는 고정
    setMenusAtom([getAppHomeInfo('홈'), ...userApps]);
  }, [userApps, setMenusAtom]);

  if (error) {
    console.error('Error: ', error.message);
    throw error;
  }

  // 사용자가 활성화한 앱만 전달한다.
  const activatedApps = menusAtom.filter((menu) => !menu.disabled);

  return <AppsDockClientInner data={activatedApps} pathname={pathname} />;
}
