import { SavePushTokenRequest, TokenListResponse } from '../types';

const API_URL = '/api/portal/common/notification/push-token';

export const getTokenList = async () => {
  const response = await fetch(API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = (await response.json()) as TokenListResponse;

  if (!response.ok) {
    throw new Error(`Failed to get token list. Message: ${data.message}`);
  }

  return data.data;
};

export const savePushToken = async (request: SavePushTokenRequest) => {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pushDevice: request.pushDevice,
      pushType: request.pushType,
      pushToken: request.pushToken,
    }),
  });

  if (!response.ok) {
    const data = (await response.json()) as { code: string; message: string };
    throw new Error(
      `Failed to save push token. Code: ${data.code}. Message: ${data.message}`,
    );
  }
};
