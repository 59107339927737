import { NotificationItemData } from '../types';

export const DUMMY_NOTI_DATA: NotificationItemData = {
  id: 1,
  title: 'Dummy Title',
  portalTypeList: [],
  categoryType: 'ALL',
  startDate: '',
  isPublic: false,
  content: '',
  userName: '',
  userLoginId: '',
  createdAt: '',
  updatedAt: '',
  customerAnnouncementNeighborDto: { existNext: false, existPrevious: false },
};
